import {updateUserAction, updateIsAuthenticated} from './auth';
import jwtDecode from 'jwt-decode';
import Constants from '../utils/params';

/*
    Modifie la position du header
 */
export function setHeaderPosition(direction, position)
{
    return(dispatch)=> {
        dispatch({
            type: "SET_HEADER",
            direction: direction,
            position: position
        });
    }
}

export function logout()
{
    updateUserAction({});
    updateIsAuthenticated(false);
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('key')
}

export function checkToken(dispatch)
{
    return new Promise(function (resolve, reject) {
        let refresh_token = localStorage.getItem('refresh_token');
        let token = localStorage.getItem('token');
        let key = localStorage.getItem('key');
        if (tokenIsExpired() && token) {
            if (refresh_token) {
                return refreshTokenAction()
                    .then(t => {
                        if(t){
                            localStorage.setItem('token', t.token);
                            localStorage.setItem('refresh_token', t.refresh_token);
                            localStorage.setItem('key', key);
                            resolve({token: t.token, refresh_token: t.refresh_token, key: key})
                        }else{
                            reject(false)
                        }
                    })
                    .catch(err=>{
                        logout()
                        reject(false)
                    })
            } else {
                logout()
                reject(false)
            }
        } else {
            resolve({token: token, refresh_token: refresh_token, key: key})
        }
    });
}

export function tokenIsExpired()
{
    let token = localStorage.getItem('token');
    if(!token)
        return true
    let tokenDecoded = jwtDecode(token)
    let now = Date.now() / 1000;
    return (tokenDecoded.exp <= now)
}

/*
  Récupère un nouveau token
 */
export function refreshTokenAction()
{
    let refresh_token = localStorage.getItem('refresh_token');
    return fetch(`${Constants.DOMAIN}/token`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({refresh_token: refresh_token})
    }).then(handleErrors)
        .then(res=>{
            return(res)
        })
        .catch(catchErrors)
}

export function getUserInfosAction()
{
    let token = localStorage.getItem('token');
    return fetch(`${Constants.DOMAIN}/auth/me`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token
        }
    }).then(handleErrors)
        .then(res=>{
            return(res)
        })
        .catch(catchErrors)
}

export function handleErrors(res)
{
    if (!res.ok) {
        throw res.json()
    }else {
        try {
            let response = res.json()
            return response;
        } catch (e) {
            console.log(e)
        }
    }
}

export function catchErrors(err)
{
    try {
        let response = err.json();
        if (response && response.error)
            console.log(response.error);
        throw err;
    } catch (e) {
        throw err;
    }
}