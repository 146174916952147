import React from 'react';
import {connect} from "react-redux";
import * as actionCreators from "../actions/actionCreators"
import Home from "../components/homeUser.js";
import TimerMixin  from 'react-timer-mixin';
import reactMixin from 'react-mixin';
import {getMedia} from '../utils/api-request'
import hash from 'object-hash'

class HomeCon extends React.Component{
    constructor(props) {
        super(props);
        this.loadMoreTweetits = this.loadMoreTweetits.bind(this);
        this.fastSubmitTweetit = this.fastSubmitTweetit.bind(this);

        this.state = {
            snackbarIsOpen: false,
            snackbarMessage: "",
            dialogIsOpen: false,
            dialogRefusalIsOpen: false,
            confirmIsOpen: false,
            confirmTweetitId: null,
            formData: {},
            lastTweetitsLength: 0,
            hasMore: true,
            posting: false,
            loadingMoreSuggested: false,
        };
    }


    componentWillMount() {
        this.props.getTweetitsUserAction(5)

        /* Auto refresh */
        this.timer = setInterval(() => {
            if(this.props.tweetits_suggested.length > 0){
                this.props.getNewUserTweetitsAction(this.props.tweetits_suggested.sort((a,b) => {return new Date(b.edited_at) - new Date(a.edited_at);})[0].edited_at, this.props.tweetits_suggested.map(tw => tw._id)).then(res=>{
                    let len = this.props.tweetits_suggested.length + (res ? res.length : 0);
                    this.props.refreshUserTweetitsAction(this.props.tweetits_suggested.map(tw => tw._id), len, hash(this.props.tweetits_suggested.concat(res)))
                })
            }else {
               this.props.getTweetitsUserAction(5)
            }

        }, 15000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    openSnackbar = (message) => {
        let _this = this;
        this.setState({ snackbarIsOpen: true, snackbarMessage: message }, res => {
            setTimeout(function(){_this.setState({ snackbarIsOpen: false, snackbarMessage: "" })}, 2000);
        });
    };

    openTweetitRefusalDialog = (data) => {
        this.setState({ dialogRefusalIsOpen: true, formData: Object.assign(data, this.state.formData)});
    };

    closeTweetitRefusalDialog = () => {
        this.setState({ dialogRefusalIsOpen: false, formData: {} });
    };

    openTweetitDialog = (data) => {
        this.setState({ dialogIsOpen: true, formData: Object.assign(data, this.state.formData)});
    };

    closeTweetitDialog = () => {
        this.setState({ dialogIsOpen: false, formData: {} });
    };

    fastSubmitTweetit(tweetit_id, data) {
        data.status = "published";

        this.setState({posting: true}, then => {

            if(data.type === "tweet"){
                this.props.sendTweetAction(tweetit_id).then(res => {
                    this.openSnackbar("Votre tweet a été envoyé.");
                    this.props.editTweetitUserAction(tweetit_id, data).then(res => {
                        this.props.getTweetitsUserAction(5);
                        this.setState({posting: false})
                    })
                }).catch(err=> {
                    if("message" in err && err.message === "Status is a duplicate."){
                        this.openSnackbar("Ce tweet existe déjà");
                    }else{
                        this.openSnackbar("Une erreur s'est produite.");
                    }
                    this.setState({posting: false})
                })
            } else if(data.type === "retweet"){
                let twitterIdRegex = /status\/([0-9a-zA-Z]*)+$/ig
                let tweet_id = twitterIdRegex.exec(data.url);
                if (tweet_id[1]) {
                    this.props.createReTweetAction(tweet_id[1]).then(res => {
                        this.openSnackbar("Votre retweet a été envoyé.");
                        this.props.editTweetitUserAction(tweetit_id, data).then(res => {
                            this.props.getTweetitsUserAction(this.props.tweetits_suggested.length);
                            this.setState({posting: false})
                        })
                    }).catch(err=> {
                        this.openSnackbar("Une erreur s'est produite.");
                        this.setState({posting: false})
                    })
                }
            }

        })
    }

    loadMoreTweetits(length, nb, status) {
        this.setState({loadingMoreSuggested: true}, () => {
            this.props.getTweetitsUserAction(nb + length).then(res => {
                if(this.state.lastTweetitsLength === res.filter(t => t.status === status).length){
                    this.setState({loadingMoreSuggested: false, hasMore: false, lastTweetitsLength: res.filter(t => t.status === status).length });
                } else {
                    this.setState({loadingMoreSuggested: false, lastTweetitsLength: res.filter(t => t.status === status).length})
                }
            })
            .catch(e => {
                this.setState({loadingMoreSuggested:false})
            });
        })
    }

    render(){
      return(
        <Home
            history={this.props.history}
            formData={this.state.formData}
            user={this.props.user}
            getMedia={getMedia}
            tweetits={this.props.tweetits_suggested ? this.props.tweetits_suggested.sort((a,b) => {return new Date(b.edited_at) - new Date(a.edited_at);}) : []}
            isLoading={this.props.isLoading_suggested}
            dialogIsOpen={this.state.dialogIsOpen}
            dialogRefusalIsOpen={this.state.dialogRefusalIsOpen}
            confirmIsOpen={this.state.confirmIsOpen}
            openTweetitDialog={this.openTweetitDialog}
            closeTweetitDialog={this.closeTweetitDialog}
            openTweetitRefusalDialog={this.openTweetitRefusalDialog}
            closeTweetitRefusalDialog={this.closeTweetitRefusalDialog}
            getTweetAction={this.props.getTweetAction}
            getTweetitsUserAction={this.props.getTweetitsUserAction}
            fastSubmitTweetit={this.fastSubmitTweetit.bind(this)}
            editTweetitUserAction={this.props.editTweetitUserAction}
            sendTweetAction={this.props.sendTweetAction}
            createReTweetAction={this.props.createReTweetAction}
            openSnackbar={this.openSnackbar}
            loadMoreTweetits={this.loadMoreTweetits}
            hasMore={this.state.hasMore}
            snackbarMessage={this.state.snackbarMessage}
            snackbarIsOpen={this.state.snackbarIsOpen}
            loading={this.props.loading}
            setHeaderPosition={this.props.setHeaderPosition}
            header={this.props.header}
            loadingMoreSuggested={this.state.loadingMoreSuggested}
            setUserScrollRef={this.props.setUserScrollRef}
        />
      )
    }
}

const mapStateToProps=(state)=>{
    return state
};

reactMixin(HomeCon.prototype, TimerMixin);
export default connect (mapStateToProps, actionCreators)(HomeCon);
