import React from 'react';
import {connect} from "react-redux";
import * as actionCreators from "../actions/actionCreators"
import SignIn from "../components/signIn.js";



class SignInCon extends React.Component{

    constructor(props) {
        super(props);
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSuccess = this.onSuccess.bind(this);

        this.state = {
            email: null,
            password: null,
            error: false,
        }
    }

    logout = () => {
        this.props.updateUserAction({});
        this.props.updateIsAuthenticated(false);
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('key')
    };

    onSuccess = (response) => {
        const token = response.headers.get('x-auth-token');
        if(response.ok){
            response.json().then(user => {
                if (token) {
                    localStorage.setItem('token', token);
                    localStorage.setItem('refresh_token', user.refresh_token);
                    localStorage.setItem('key', user.key);
                    this.props.getCurrentUserAction(token, user.refresh_token, user.key).then(res => {
                        this.props.updateIsAuthenticated(true);
                    })
                }
            });
        }else{
            this.setState({error: true})
            this.props.updateIsAuthenticated(false);
        }
    };

    onFailed = (error) => {
        this.setState({error: true})
        this.props.updateIsAuthenticated(false);
    };

    handleLoginSubmit(e) {
        e.preventDefault();

        if (this.state.email && this.state.password){
            this.props.loginAction(this.state.email, this.state.password)
                .then(res => {
                    this.onSuccess(res)
                })
                .catch(err => {
                    this.props.updateIsAuthenticated(false);
                })
        }
    };

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    render(){
        return(
            <SignIn
                error={this.state.error}
                onFailed={this.onFailed}
                onSuccess={this.onSuccess}
                logout={this.logout}
                handleLoginSubmit={this.handleLoginSubmit}
                handleChange={this.handleChange}
                email={this.state.email}
                password={this.state.password}
            />
        )
    }
}

const mapStateToProps=(state)=>{
    return state
};

export default connect (mapStateToProps, actionCreators)(SignInCon);
