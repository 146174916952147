import Constants from '../utils/params';
import hash from "object-hash";
import {handleErrors, catchErrors, checkToken} from './utils';

/*
  Envoi d'un tweet
  data = {message(string), media(base64), media_alt(string)}
 */
export function sendTweetAction(tweetit_id)
{
  return(dispatch)=>{
      return checkToken().then((t)=>{
          dispatch({
              type:"UPDATE_LOADING",
              loading:true,
          });
        return fetch(`${Constants.DOMAIN}/tweet/send?tweetit=` + tweetit_id, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': t.token,
            'key': t.key
          }
        }).then(handleErrors)
        .then(res=>{
            dispatch({
                type:"UPDATE_LOADING",
                loading:false,
            });
            dispatch({
                type:"REMOVE_TWEETIT",
                tweetit_id:tweetit_id,
            });
          return(res)
        })
        .catch(catchErrors)
      });
  }
}

/*
  Vérification des accès twitter
 */
export function checkTwitterOauthAction()
{
  return(dispatch)=>{
      return checkToken().then((t)=>{
          return fetch(`${Constants.DOMAIN}/verify-credentials`, {
              method: 'get',
              headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': t.token,
                  'key': t.key
              }
          }).then(handleErrors)
              .then(res=>{
                  return(res)
              })
              .catch(catchErrors)
      })
  }
}

/*
  Suppression d'un tweet
 */
export function deleteTweetAction(tweet_id)
{
  return(dispatch)=>{
      return checkToken().then((t)=> {
          return fetch(`${Constants.DOMAIN}/tweet`, {
              method: 'delete',
              headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': t.token,
                  'key': t.key
              },
              body: JSON.stringify({tweet: tweet_id})
          }).then(handleErrors)
              .then(res => {
                  return (res)
              })
              .catch(catchErrors)
      });
  }
}

/*
  Création d'un retweet
 */
export function createReTweetAction(tweet_id)
{
  return(dispatch)=>{
      return checkToken().then((t)=> {
          return fetch(`${Constants.DOMAIN}/retweet`, {
              method: 'post',
              headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': t.token,
                  'key': t.key
              },
              body: JSON.stringify({tweet: tweet_id})
          }).then(handleErrors)
              .then(res => {
                  return (res)
              })
              .catch(catchErrors)
      });
  }
}

/*
  Récupère la liste des tweets
 */
export function getTweetsAction(limit)
{
  return(dispatch)=>{
      return checkToken().then((t)=> {
          return fetch(`${Constants.DOMAIN}/tweets`, {limit: limit ? limit : 0}, {
              method: 'get',
              headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': t.token,
                  'key': t.key
              }
          }).then(handleErrors)
              .then(res => {
                  return (res)
              })
              .catch(catchErrors)
      });
  }
}

/*
  Récupère la liste des tweetits (les tweets suggéré)
 */
export function getTweetitsUserAction(limit, skip)
{
  return(dispatch)=>{
      return checkToken().then((t)=> {
          return fetch(`${Constants.DOMAIN}/tweetits/user?limit=` + (limit ? limit : 0) + '&skip=' + (skip ? skip : 0), {
              method: 'get',
              headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': t.token
              }
          }).then(handleErrors)
              .then(res => {
                  dispatch({
                      type: "UPDATE_SUGGESTED_TWEETITS",
                      tweetits: res
                  });
                  return (res)
              })
              .catch(catchErrors)
      });
  }
}

/*
  Récupère les nouveau tweetits excluant ceux en paramètre
 */
export function getNewUserTweetitsAction(after, tweetits)
{
    return(dispatch)=>{
        return checkToken().then((t)=> {
            return fetch(`${Constants.DOMAIN}/tweetits/user/except?tweetits=` + JSON.stringify(tweetits) + "&after=" + after, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': t.token
                }

            }).then(handleErrors)
                .then(res => {
                    dispatch({
                        type: "ADD_TWEETITS",
                        tweetits: res,
                        status: "suggested",
                    });
                })
                .catch(catchErrors)
        });
    }
}



/*
  Met à jour la liste des tweetits si hash différent
 */
export function refreshUserTweetitsAction(tweetits, len, last_hash, status)
{
    return(dispatch)=>{
        return checkToken().then((t)=> {
            return fetch(`${Constants.DOMAIN}/tweetits/user?limit=` + (len ? len : 0) + (status ? ('&status=' + status.join()) : "") + "&tweetits=" + JSON.stringify(tweetits), {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': t.token
                }

            }).then(handleErrors)
                .then(res => {
                    if (last_hash !== hash(res)) {
                        dispatch({
                            type: "UPDATE_SUGGESTED_TWEETITS",
                            tweetits: res
                        });
                    }
                    return res;
                })
                .catch(catchErrors)
        });
    }
}

/*
  Edition d'un tweetit
  data = {message(string), media_alt(string), media(base64)}
 */
export function editTweetitUserAction(tweetit_id, data)
{
    return(dispatch)=>{
        return checkToken().then((t)=> {
            /* Supprime les champs vides */
            Object.keys(data).forEach(key => (data[key] === null && key !== "media") && delete data[key]);

            if (data.tweet_ref)
                data.tweet_ref = JSON.stringify(data.tweet_ref)
            /* Si la champ media ne contient pas d'objet file alors on le supprime */
            if (typeof data.media !== "object" && data.media != null)
                delete data.media

            var formData = new FormData();
            for (var key in data) {
                formData.append(key, data[key]);
            }
            formData.append("tweet", tweetit_id);
            return fetch(`${Constants.DOMAIN}/tweetit/user`, {
                method: 'put',
                headers: {
                    'x-auth-token': t.token,
                    'key': t.key
                },
                body: formData
            }).then(handleErrors)
                .then(res => {
                    return (res)
                })
                .catch(catchErrors)
        });
    }
}