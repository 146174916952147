var DOMAIN = 'http://localhost:4000/api/v1'

switch(process.env.REACT_APP_MODE){
    case "dev":
        DOMAIN = 'https://staging-tweetit-api.herokuapp.com/api/v1'
        break;
    case "preprod":
        DOMAIN = 'http://preprodapi.tweetit.io/api/v1'
        break;
    case "prod":
        DOMAIN = 'https://api.tweetit.io/api/v1'
        break;
    default:
        DOMAIN = 'http://localhost:4000/api/v1';
        break;
}

const UPDATE_USERS = 'UPDATE_USERS';
const UPDATE_USER = 'UPDATE_USER';
const UPDATE_ISAUTHENTICATED = 'UPDATE_ISAUTHENTICATED';

const UPDATE_DRAFT_TWEETITS = 'UPDATE_DRAFT_TWEETITS';
const UPDATE_SUGGESTED_TWEETITS = 'UPDATE_SUGGESTED_TWEETITS';
const UPDATE_TWEETITS = 'UPDATE_TWEETITS';

const ADD_TWEETIT = 'ADD_TWEETIT';
const ADD_TWEETITS = 'ADD_TWEETITS';
const REMOVE_TWEETIT = 'REMOVE_TWEETIT';
const REPLACE_TWEETIT = 'REPLACE_TWEETIT';
const UPDATE_TWEETIT = 'UPDATE_TWEETIT';

const UPDATE_LOADING = 'UPDATE_LOADING';

const SET_HEADER = 'SET_HEADER';

export default {
    DOMAIN,
    UPDATE_USER,
    UPDATE_USERS,
    UPDATE_ISAUTHENTICATED,
    ADD_TWEETIT,
    REPLACE_TWEETIT,
    ADD_TWEETITS,
    UPDATE_DRAFT_TWEETITS,
    UPDATE_SUGGESTED_TWEETITS,
    UPDATE_TWEETITS,
    REMOVE_TWEETIT,
    UPDATE_TWEETIT,
    UPDATE_LOADING,
    SET_HEADER,
}
