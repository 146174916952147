import Constants from '../utils/params';
import {handleErrors, catchErrors, checkToken} from './utils';

/*
  Modification du role d'un utilisateur
 */
export function editAccountRoleAction(user_id, role)
{
  return(dispatch)=>{
      return checkToken().then((t)=> {
          return fetch(`${Constants.DOMAIN}/user/role`, {
              method: 'put',
              headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': t.token
              },
              body: JSON.stringify({user: user_id, role: role})
          }).then(handleErrors)
              .then(res => {
                  return (res)
              })
              .catch(catchErrors)
      });
  }
}

/*
  Modification d'un utilisateur
 */
export function editAccountAction(user_id, email, phone, status, role)
{
    return(dispatch)=>{
        return checkToken().then((t)=> {
            return fetch(`${Constants.DOMAIN}/user`, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': t.token
                },
                body: JSON.stringify({user: user_id, email: email, phone: phone, status:status, role:role})
            }).then(handleErrors)
                .then(res => {
                    dispatch({
                        type: "UPDATE_USER",
                        user: res
                    });
                    return (res)
                })
                .catch(catchErrors)
        });
    }
}

/*
  Ajout d'un utilisateur
 */
export function addAccountAction(screen_name, email, phone, role)
{
    return(dispatch)=>{
        return checkToken().then((t)=> {
            return fetch(`${Constants.DOMAIN}/user`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': t.token
                },
                body: JSON.stringify({screen_name:screen_name, email: email, phone: phone, role:role})
            }).then(handleErrors)
                .then(res => {
                    return (res)
                })
                .catch(catchErrors)
        });
    }
}

/*
  Récupère la liste des utilisateurs
 */
export function getUsersAction()
{
  return(dispatch)=>{

      return checkToken().then((t)=> {
          return fetch(`${Constants.DOMAIN}/users`, {
              method: 'get',
              headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': t.token
              }
          }).then(handleErrors)
              .then(res => {
                  dispatch({
                      type: "UPDATE_USERS",
                      users: res
                  });
                  return (res)
              })
              .catch(catchErrors)
      });
  }
}

/*
  Suppression d'un compte utilisateur
 */
export function deleteUserAction( user_id)
{
  return(dispatch)=>{
      return checkToken().then((t)=> {
          return fetch(`${Constants.DOMAIN}/user`, {
              method: 'delete',
              headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': t.token
              },
              body: JSON.stringify({user: user_id})
          }).then(handleErrors)
              .then(res => {
                  return (res)
              })
              .catch(catchErrors)
      });
  }
}

/*
  Récupère la liste des tweetits
 */
export function getTweetitsAdminAction(limit)
{
  return(dispatch)=>{
      return checkToken().then((t)=> {
          return fetch(`${Constants.DOMAIN}/tweetits/admin`, {limit: limit ? limit : 0}, {
              method: 'get',
              headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': t.token
              }
          }).then(handleErrors)
              .then(res => {
                  return (res)
              })
              .catch(catchErrors)
      });
  }
}

/*
  Récupère la liste des tweetits d'un utilisateur
 */
export function getUserTweetitsAction(user_id, limit)
{
  return(dispatch)=>{
      return checkToken().then((t)=> {
          return fetch(`${Constants.DOMAIN}/tweetits/admin`, {user: user_id, limit: limit ? limit : 0}, {
              method: 'get',
              headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': t.token
              }
          }).then(handleErrors)
              .then(res => {
                  return (res)
              })
              .catch(catchErrors)
      });
  }
}

/*
  Récupère la liste des refresh_tokens
 */
export function getRefreshTokensAction(limit)
{
  return(dispatch)=>{
      return checkToken().then((t)=> {
          return fetch(`${Constants.DOMAIN}/refresh-tokens`, {limit: limit ? limit : 0}, {
              method: 'get',
              headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': t.token
              }
          }).then(handleErrors)
              .then(res => {
                  return (res)
              })
              .catch(catchErrors)
      });
  }
}

/*
  Supprime le refresh_token d'un utilisateur, à l'expiration de son token il devra se reconnecter
 */
export function deleteUserRefreshTokenAction(user_id)
{
  return(dispatch)=>{
      return checkToken().then((t)=> {
          return fetch(`${Constants.DOMAIN}/token/reject`, {
              method: 'post',
              headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': t.token
              },
              body: JSON.stringify({user: user_id})
          }).then(handleErrors)
              .then(res => {
                  return (res)
              })
              .catch(catchErrors)
      });
  }
}

/*
  Suppression d'un tweetit
 */
export function deleteTweetitAdminAction(tweetit_id)
{
  return(dispatch)=>{
      return checkToken().then((t)=> {
          return fetch(`${Constants.DOMAIN}/tweetit/admin`, {
              method: 'delete',
              headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': t.token
              },
              body: JSON.stringify({tweet: tweetit_id})
          }).then(handleErrors)
              .then(res => {
                  return (res)
              })
              .catch(catchErrors)
      });
  }
}
