import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowBackIcon from '../../assets/images/arrowDark.png';
import Slide from '@material-ui/core/Slide';
import ReactSVG from "react-svg";
import sendIcon from "../../assets/images/send.svg";
import TextField from "@material-ui/core/TextField/TextField";
import xss from "xss";
import {readAsDataURL} from 'promise-file-reader';
import ok from "../../assets/images/ok.svg";
import wrong from "../../assets/images/wrong.svg";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import IconButton from "@material-ui/core/IconButton/IconButton";
import addImageIcon from "../../assets/images/img.svg";
import base64 from "base64-arraybuffer";
import diff from 'deep-diff';

const styles = theme => ({
    appBar: {
        position: 'relative',
        backgroundColor: "#ffffff",
        boxShadow: "none",
    },
    rootGrid: {
        padding: "0 25px 30px 25px",
        margin: 0,
        width: "100%",
    },
    flex: {
        flex: 1,
    },
    title:{
        fontSize: 20,
        fontWeight: 700,
        color: "rgb(134, 161, 178)",
    },
    actions:{
        justifyContent: "space-between",
        padding:"25px 25px 30px 25px",
        height: 36,
        minHeight: 36,
    },
    leftIcon: {
        marginRight: 10,
        width: 16,
        height: 16,
    },
    textField: {
        backgroundColor: "#ffffff",
        width: "100%",
        margin: "34px 0 11px 0",
        borderRadius: 10,
        "& fieldset": {
            borderRadius: 10,
            border: "solid 1px #c3d0d7",
        },
        "& input": {
            padding: "18.5px 35px 18.5px 14px",
        }
    },
    buttonSubmit: {
        height: 36,
        margin: 0,
        padding: "5px 16px",
        color: "#ffffff",
        textTransform: "Capitalize",
        boxShadow: "none",
        float: "right",
        '&:active': {
            boxShadow: "none",
        }
    },
    buttonEdit: {
        height: 36,
        margin: 0,
        padding: "5px 16px",
        boxShadow: "none",
        borderRadius: 5,
        border: "solid 1px #dbdbdb",
        color: "#697e8b",
        fontWeight: 600,
        backgroundColor: "#ffffff",
        textTransform: "Capitalize",
        '&:active': {
            boxShadow: "none",
        },
        '& img': {
            padding: "0 9px 0 0",
        }
    },
    tweetCount: {
        position: "absolute",
        right: 40,
        top: 345,
        color: "#23e27f",
        fontWeight: 600,
        fontSize: 12,
    },
    checkCircle: {
        position: "absolute",
        color: "#23e27f",
        width: 19,
        height: 19,
        right: 37,
        top: 171,
    },
    input: {
        display: 'none',
    },
    media: {
        marginTop: 10,
        width: "100%",
        height: 200,
        objectFit: "cover",
        borderRadius: 5,
        border: "1px solid rgb(219, 219, 219)",
    },
    buttonRemoveMedia: {
        position: "absolute",
        right: 0,
        top: 10,
        '& rect': {
            fill: "#525252",
        }
    },
    progress: {
        margin: "0 10px 0 0",
    },
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class FullScreenDialog extends React.Component {
    constructor(props) {
        super(props);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileRemove = this.handleFileRemove.bind(this);
        this.editTweetit = this.editTweetit.bind(this);

        this.state = {
            value: 0,
            formDataHash: null,
            formData: {
                id: null,
                users: null,
                message: "",
                media: null,
                media_alt: null,
                url: "",
                status: "",
                type: "",
                tweet_ref: null,
            },
            usersIsOpen: false,
            urlIsLoading: false,
            urlIsValid: undefined,
            messageIsValid: undefined,
            posting:false,
            loaded: false,
        };
    }

    /* Vérifie le contenu du message et de url */
    validateContent(message, url) {
        let twitterIdRegex = /status\/([0-9a-zA-Z]*)+$/ig
        let tweet_id = twitterIdRegex.exec(url);

        if(!tweet_id || (tweet_id && tweet_id.length < 2)) {
            this.setState({urlIsValid: false});
        } else if(this.state.value === 1){
            /* Extrait l'id du tweet depuis l'url ensuite demande une vérification à l'api */
            if (tweet_id[1]) {
                this.setState({urlIsLoading: true}, r => {
                    this.props.getTweetAction(tweet_id[1])
                        .then(res => {
                            let formData = Object.assign({}, this.state.formData);
                            formData.message = res.text;
                            formData.tweet_ref = {};
                            formData.tweet_ref.id = res.id_str;
                            formData.tweet_ref.created_at = res.created_at;
                            formData.tweet_ref.lang = res.lang;
                            formData.tweet_ref.retweet_count = res.retweet_count;
                            formData.tweet_ref.favorite_count = res.favorite_count;
                            formData.tweet_ref.user = {};
                            if(res.user){
                                formData.tweet_ref.user.id = res.user.id_str;
                                formData.tweet_ref.user.created_at = res.user.created_at;
                                formData.tweet_ref.user.lang = res.user.lang;
                                formData.tweet_ref.user.name = res.user.name;
                                formData.tweet_ref.user.screen_name = res.user.screen_name;
                                formData.tweet_ref.user.profile_image_url_https = res.user.profile_image_url_https;
                            }

                            /*  Récupère l'image en base 64 */
                            if("media" in res.entities && res.entities.media.length > 0 && res.entities.media[0].type === "photo"){
                                fetch(res.entities.media[0].media_url_https, {
                                    method: 'GET',
                                }).then((response) => {
                                    response.arrayBuffer().then((buffer) => {
                                        var base64Flag = 'data:image/jpeg;base64,';
                                        var binary = '';
                                        var bytes = [].slice.call(new Uint8Array(buffer));
                                        bytes.forEach((b) => binary += String.fromCharCode(b));
                                        var imageStr = window.btoa(binary);
                                        formData.media = base64Flag + imageStr;
                                        formData.media_alt = "image";
                                        this.setState({urlIsValid: true, urlIsLoading: false, formData: formData});
                                    });
                                })
                                    .catch((err)=>{
                                        this.setState({urlIsValid: false, urlIsLoading: false, formData: formData});
                                    })
                            }else{
                                formData.media = null;
                                formData.media_alt = null;
                            }

                            this.setState({urlIsValid: true, urlIsLoading: false, formData: formData});
                        })
                        .catch(err => {
                            this.setState({urlIsValid: false, urlIsLoading: false});
                        })
                })
            } else {
                this.setState({urlIsValid: false});
            }
        } else {
            this.setState({urlIsValid: true});
        }
        this.setState({messageIsValid: true});
    }

    componentWillReceiveProps(nextProps) {
        if(!nextProps.open){
            this.setState({loaded: false});
        }
        if(nextProps.formData && Object.keys(nextProps.formData).length > 0 && nextProps.formData.constructor === Object) {
            if(!this.state.loaded){
                let formData = Object.assign({}, this.state.formData);
                formData = nextProps.formData;
                /* Form validation */
                this.validateContent(nextProps.formData.message, nextProps.formData.url);

                formData.users = formData.childs.map(child => child.user_id);

                if(formData.media)
                    this.getMedia(formData);

                /*  Vérifie si le type pour affiche la bonne fenètre */
                this.setState({loaded: true, formDataHash: formData,formData: formData, value: (formData.type === "retweet") ? 1 : 0});

            }
        }else {
            this.setState({
                value: 0,
                formDataHash: null,
                formData: {
                    id: null,
                    users: null,
                    message: "",
                    media: "",
                    media_alt: null,
                    url: "",
                    status: "",
                    tweet_ref: null,
                },
                urlIsLoading: false,
                urlIsValid: undefined,
                messageIsValid: undefined,
                posting:false,
            })
        }
    }

    handleChange = name => event => {
        /* Form validation */
        if (name === "url") {
            this.validateContent(xss(this.state.formData.message), xss(event.target.value));
        } else if (name === "message") {
            this.validateContent(xss(event.target.value), xss(this.state.formData.url));
        }

        let formData = Object.assign({}, this.state.formData);
        formData[name] = event.target.value;
        this.setState({formData: formData});
    };

    getBase64(file) {
        return readAsDataURL(file)
            .then(res => {
                return Promise.resolve(res);
            })
            .catch(err => {
                return Promise.reject(err)
            });
    }

    handleFileChange(event) {
        let _this = this;
        const target = event.target;
        if(target.files && target.files.length > 0){
            let msg_error = null;
            if(target.files[0].type === "image/gif" && target.files[0].size > 15000000){
                msg_error = "Votre gif doit être inférieur à 15mb.";
            }else if(target.files[0].type === "image/jpg" && target.files[0].size > 5000000){
                msg_error = "Votre image doit être inférieure à 5mb.";
            }else if(target.files[0].type === "image/jpeg" && target.files[0].size > 5000000){
                msg_error = "Votre image doit être inférieure à 5mb.";
            }else if(target.files[0].type === "image/png" && target.files[0].size > 5000000){
                msg_error = "Votre image doit être inférieure à 5mb.";
            }else if(target.files[0].type !== "image/png" && target.files[0].type !== "image/jpeg" && target.files[0].type !== "image/jpg" && target.files[0].type !== "image/gif"){
                msg_error = "Format non pris en charge.";
            }


            if (!msg_error) {
                let formData = Object.assign({}, _this.state.formData);
                formData.media = target.files[0];
                let reader = new FileReader();
                reader.onload = (e) => {
                    _this.setState({formData: formData, imageError: null, img_src: e.target.result});
                };
                reader.readAsDataURL(target.files[0]);
            } else {
                this.setState({imageError: msg_error})
            }
        }
    }

    handleFileRemove() {
        let formData = Object.assign({}, this.state.formData);
        formData.media = null;
        this.setState({formData: formData});
    }

    editTweetit(e, status) {
        this.setState({posting: true}, then => {
            if(this.state.formData.type === "tweet"){
                    this.props.editTweetitUserAction(this.state.formData.id, {
                        message: this.state.formData.message,
                        media: this.state.formData.media ? this.state.formData.media : null,
                        url: this.state.formData.url ? this.state.formData.url : null,
                        status: (diff(this.state.formDataHash, this.state.formData) ? "edited" : "published"),
                    })
                    .then(res => {
                        this.props.sendTweetAction(this.state.formData.id)
                            .then(res => {
                                this.props.openSnackbar("Votre tweet a été envoyé.");
                                this.setState({
                                    value: 0,
                                    formDataHash: null,
                                    formData: {
                                        id: null,
                                        users: null,
                                        message: "",
                                        media: null,
                                        media_alt: null,
                                        url: "",
                                        status: "",
                                        type: "",
                                        tweet_ref: null,
                                    },
                                    urlIsLoading: false,
                                    urlIsValid: undefined,
                                    messageIsValid: undefined,
                                }, res => {
                                    this.props.getTweetitsUserAction(this.props.tweetits.filter(t => t.status === "suggested").length);
                                    this.props.handleClose();
                                    this.setState({posting: false})
                                })
                            })
                            .catch(err=> {
                                if("message" in err && err.message === "Status is a duplicate."){
                                    this.props.openSnackbar("Ce tweet existe déjà");
                                }else{
                                    this.props.openSnackbar("Une erreur s'est produite.");
                                }
                                this.props.openSnackbar("Une erreur s'est produite.");
                                this.setState({posting: false})
                            })
                    })
                    .catch(err=> {
                        this.props.openSnackbar("Une erreur s'est produite.");
                        this.setState({posting: false})
                    })
            } else if(this.state.formData.type === "retweet"){
                let twitterIdRegex = /status\/([0-9a-zA-Z]*)+$/ig
                let tweet_id = twitterIdRegex.exec(this.state.formData.url);
                if (tweet_id[1]) {
                    this.props.editTweetitUserAction(this.state.formData.id, {
                        message: this.state.formData.message,
                        media: this.state.formData.media ? this.state.formData.media : null,
                        url: this.state.formData.url ? this.state.formData.url : null,
                        status: (diff(this.state.formDataHash, this.state.formData) ? "edited": "published"),
                    }).then(res => {
                        this.props.createReTweetAction(tweet_id[1])
                            .then(res => {
                                this.props.openSnackbar("Votre retweet a été envoyé.");
                                this.setState({
                                    value: 0,
                                    formDataHash: null,
                                    formData: {
                                        id: null,
                                        users: null,
                                        message: "",
                                        media: null,
                                        media_alt: null,
                                        url: "",
                                        status: "",
                                        type: "",
                                        tweet_ref: null,
                                    },
                                    urlIsLoading: false,
                                    urlIsValid: undefined,
                                    messageIsValid: undefined,
                                }, res => {
                                    this.props.getTweetitsUserAction(this.props.tweetits.filter(t => t.status === "suggested").length);
                                    this.props.handleClose();
                                    this.setState({posting: false})
                                })
                            })
                            .catch(err=> {
                                this.props.openSnackbar("Une erreur s'est produite.");
                                this.setState({posting: false})
                            })
                    })
                    .catch(err=> {
                        this.props.openSnackbar("Une erreur s'est produite.");
                        this.setState({posting: false})
                    })

                }
            }

        })

    }

    getMedia(formData){
        this.props.getMedia(formData.media, formData.id).then(res => {
            res.arrayBuffer().then(r=>{
                this.setState({img_src: "data:image/gif;base64," + base64.encode(r)})
            })
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog
                    fullScreen
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar className={classes.actions}>
                            <Button variant="contained" size="small" className={classes.buttonEdit} onClick={this.props.handleClose}>
                                <img alt={"btn_retour"} src={ArrowBackIcon} />
                                Retour
                            </Button>
                            <Button variant="contained" color="primary" className={classes.buttonSubmit}
                                    onClick={(e) => this.editTweetit(e, "published")}
                                    disabled={
                                        (
                                            (!this.state.messageIsValid && this.state.formData.type === "tweet") ||
                                            (!this.state.urlIsValid && this.state.formData.type === "retweet") ||
                                            (this.state.posting)
                                        )
                                    }
                            >
                                {this.state.posting ? (
                                    <CircularProgress className={this.props.classes.progress}  size={20} />
                                ) : (
                                    <ReactSVG src={sendIcon} className={classes.leftIcon }/>
                                )}
                                {"Twitter"}
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Grid container className={classes.rootGrid} spacing={16} >
                        <Grid item xs={12} style={{padding: 0}} >
                            <Typography className={classes.title}>
                                {this.state.formData.type === "tweet" ? "Editer le tweet" : "Editer le retweet"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{padding: 0}} >
                            <TextField
                                id="outlined-multiline-flexible"
                                label=""
                                multiline={this.state.formData.type === "tweet" ? true : false}
                                rows="10"
                                placeholder="Modifier un tweet"
                                rowsMax="10"
                                value={this.state.formData.type === "tweet" ? this.state.formData.message : this.state.formData.url}
                                onChange={this.state.formData.type === "tweet" ? this.handleChange('message') : this.handleChange('url')}
                                className={classes.textField}
                                margin="normal"
                                inputProps={{ maxLength: 280 }}
                                variant="outlined"
                            />
                            {this.state.formData.type === "tweet" && (
                                <Typography component={"p"} style={
                                    {color: this.state.formData.message.length < 280 ? "#23e27f" : "#f2615e"}
                                } className={classes.tweetCount}>
                                    {this.state.formData.message.length}
                                </Typography>
                            )}
                            {(this.state.formData.type === "retweet" &&this.state.urlIsValid !== undefined)  && (
                                <Grid item className={classes.checkCircle}>
                                    {(!this.state.urlIsLoading && this.state.urlIsValid) && (
                                        <ReactSVG src={ok} />
                                    )}
                                    {(!this.state.urlIsLoading && !this.state.urlIsValid) && (
                                        <ReactSVG src={wrong} />
                                    )}
                                    {this.state.urlIsLoading && (
                                        <CircularProgress className={classes.progress} size={20}/>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                        {this.state.formData.type === "tweet" && (
                            <Grid item xs={12} className={classes.itemGrid} style={{padding: 0}}>
                                {(this.state.formData.media && this.state.img_src)? (
                                    <div style={{position: "relative"}}>
                                        <img alt={this.state.formData.media_alt} src={this.state.img_src} className={this.props.classes.media}/>
                                        <IconButton
                                            onClick={this.handleFileRemove}
                                            className={classes.buttonRemoveMedia + " noOver"}
                                            disableRipple>
                                            <ReactSVG src={wrong} />
                                        </IconButton>
                                    </div>
                                ) : (
                                    <div >
                                        <input
                                            accept="image/*"
                                            className={this.props.classes.input}
                                            onChange={this.handleFileChange}
                                            id="media"
                                            name="media"
                                            type="file"
                                        />
                                        <label htmlFor="media">
                                            <IconButton
                                                variant="contained" component="span"
                                                className={classes.buttonAddImage + " noOver"}
                                                disableRipple>
                                                <ReactSVG src={addImageIcon} className={this.props.classes.leftIcon}/>
                                            </IconButton>
                                        </label>
                                        {this.state.imageError && (
                                            <Typography style={{color: "#ff0000"}}>{this.state.imageError}</Typography>
                                        )}
                                    </div>
                                )}

                            </Grid>
                        )}
                        </Grid>

                </Dialog>
            </div>
        );
    }
}

FullScreenDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullScreenDialog);