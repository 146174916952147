import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route} from 'react-router-dom'
import * as actionCreators from "./actions/actionCreators"
import {connect} from "react-redux";
import HeaderCon from "./containers/header-container.js"
import HomeManagerCon from "./containers/homeManager-container.js"
import HomeUserCon from "./containers/homeUser-container.js"
import SettingsUserCon from "./containers/settingsUser-container.js"
import SignInCon from "./containers/signIn-container.js"
import Grid from '@material-ui/core/Grid';
import {withStyles} from "@material-ui/core";
/*import Chip from "@material-ui/core/Chip/Chip";
import Avatar from "@material-ui/core/Avatar/Avatar";
import DoneIcon from "@material-ui/core/SvgIcon/SvgIcon";*/


const styles = theme => ({
    content: {

    },
});
class App extends Component {
    constructor(props) {
        super(props);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.setUserScrollRef = this.setUserScrollRef.bind(this);

        this.state={
            userScrollRef: null,
        }
    }

    componentWillMount() {
        this.loadFromCache();
    }

    loadFromCache() {
        let token = localStorage.getItem('token');
        let refresh_token = localStorage.getItem('refresh_token');
        let key = localStorage.getItem('key');

        if (token && refresh_token && key && !this.props.user) {

            this.props.checkTwitterOauthAction(token, key).then(r => {
                if(r && (r.message === "Authenticated" || r.message === "jwt expired")) {
                    this.props.getNewTokenAction(refresh_token)
                        .then(res => {
                            if (res && "refresh_token" in res){
                                this.props.getCurrentUserAction(res.token, res.refresh_token, key).then(res2 => {
                                    this.props.updateIsAuthenticated(true);
                                    localStorage.setItem('token', res.token);
                                    localStorage.setItem('refresh_token', res.refresh_token);
                                })
                            } else {
                                this.props.updateIsAuthenticated(false);
                            }
                        })
                        .catch(err => {
                            this.logout()
                        })
                }else {
                    this.logout()
                }

            })
            .catch(err => {
                this.logout()
            })
        }
    }

    logout = async () => {
        await this.props.updateUserAction({});
        await this.props.updateUsersAction([]);
        this.props.updateIsAuthenticated(false);
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('key')
    };

    setUserScrollRef(input) {
       this.setState({userScrollRef: input.current});
    }

    scrollToTop(){
        if(this.state.userScrollRef){
            const myDomNode = ReactDOM.findDOMNode(this.state.userScrollRef)
            myDomNode.scrollTo(0, 0);
        }
    }

  render() {
      const { classes } = this.props;
      let _this = this;
    return (
    <Router>
      <Grid container className="App" direction="row" >
        {!!this.props.isAuthenticated ?
            (

                <Grid container direction="row">
                    <Grid item xs={12} style={{zIndex: 2}}>
                        <Route exact path="/:path(|members|members/create|members/edit)" render={({...props})=><HeaderCon {...props} scrollToTop={_this.scrollToTop} />} />
                    </Grid>

                    {(function() {
                        switch(_this.props.user.role) {
                            case "Manager":
                                return (
                                    <Grid item xs={12} className={classes.content}>
                                        <Route exact path="/:path(|members|members/create|members/edit)" component={HomeManagerCon} />
                                    </Grid>
                                );
                            case "User":
                                return (
                                    <Grid item xs={12} className={classes.content}>
                                        <Route exact path="/" render={()=><HomeUserCon setUserScrollRef={_this.setUserScrollRef}/>} />
                                        <Route exact path="/settings" component={SettingsUserCon} />
                                    </Grid>
                                );

                            default:
                                return null;
                        }
                    })()}
                </Grid>
            ) :
            (
                <SignInCon />
            )}
      </Grid>
    </Router>
    );
  }
}

const mapStateToProps=(state)=>{
    return state
};

export default connect (mapStateToProps, actionCreators)(withStyles(styles)(App));
