import React from 'react';
import {connect} from "react-redux";
import * as actionCreators from "../actions/actionCreators";
import Members from "../components/Members.js";
import {getMedia} from '../utils/api-request'
import * as EmailValidator from 'email-validator';

class MembersCon extends React.Component{
    constructor(props) {
        super(props);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeFormData = this.handleChangeFormData.bind(this);
        this.handleChangePhoneData = this.handleChangePhoneData.bind(this);
        this.submitUser = this.submitUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.editUser = this.editUser.bind(this);
        this.sendInvitation = this.sendInvitation.bind(this);

        this.state = {
            page: 0,
            user: null,
            email: "",
            screen_name: "",
            phone: "",
            role: "User",
            loading: false,
            success: false,
            errorEmail: null,
            errorName: null,
            invitationSent: false,
        };
    }

    componentWillReceiveProps(nextprops){
        let params = new URLSearchParams(nextprops.history.location.search);
        switch(this.props.history.location.pathname){
            case "/members":
                this.handleChangePage(0, null, false)
                break;
            case "/members/create":
                this.handleChangePage(1, null, false)
                break;
            case "/members/edit":
                let user_screen_name = params.get('user')
                if(user_screen_name){
                    let user = nextprops.users.find(u => u.screen_name === user_screen_name)
                    if(!user && nextprops.user.screen_name === user_screen_name){
                        user = nextprops.user;
                    }
                    this.handleChangePage(2, user, null, false)
                }else{
                    this.handleChangePage(2, null, null, false)
                }
                break;
            default:
                break;
        }
    }

    handleChangePage(page_id, user, replace){
        this.setState({page: page_id, user: user}, ()=>{
            switch(page_id){
                case 0:
                    if(replace)
                        this.props.history.replace('/members')
                    this.setState({invitationSent: false, email:"",screen_name:"",phone:"",role:"User",errorEmail:null,errorName:null})
                    break;
                case 1:
                    if(replace)
                        this.props.history.replace('/members/create')
                    break;
                case 2:
                    if(user){
                        this.setState({email:user.email,screen_name:user.screen_name,phone:user.phone,role:user.role})
                    }
                    if(replace)
                        this.props.history.replace('/members/edit?user='+user.screen_name)
                    break;
                default:
                    break;
            }
        })
    }

    handleChangeFormData(event){
        let value = event.target.value;
        if(event.target.name === "screen_name"){
            value = value.replace('@', '');
        }
        this.setState({[event.target.name]: value})
    }

    handleChangePhoneData(number){
        this.setState({phone: number})
    }

    handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ errorEmail: null, errorName: null });
    };

    submitUser(){
        const _this = this;
        if(this.state.screen_name && (!this.state.email || (this.state.email && EmailValidator.validate(this.state.email)))) {
            if (!this.state.loading) {
                this.setState({success: false,loading: true,errorEmail:null,errorName:null},
                    ()=> {
                        this.props.addAccountAction(this.state.screen_name, this.state.email, this.state.phone, this.state.role).then(res => {
                            _this.props.getUsersRestrictedAction();
                            _this.setState({success: true,loading: false}, ()=>{
                                _this.handleChangePage(0, null, true)
                            })
                        }).catch(err=> {
                            err.then(function(object) {
                                //console.log(object.message)
                                _this.setState({success: false, loading: false, errorEmail: object.message})
                            });
                        })
                    }
                );
            }
        }else{
            if(this.state.email && (this.state.email && !EmailValidator.validate(this.state.email))){
                this.setState({ errorEmail: "Adresse email non valide" });
            }else if(!this.state.screen_name){
                this.setState({ errorName: "Compte non valide" });
            }
        }
    }

    sendInvitation(){
        if(!this.state.invitationSent && (this.state.user._id || this.state.user.id)){
            this.setState({invitationSent: true},() => {
                this.props.sendInvitationAction("_id" in this.state.user ? this.state.user._id : this.state.user.id)
                    .then(res => {
                        //
                    })
                    .catch(err => {
                        this.setState({invitationSent: false});
                    });
            })
        }
    }

    editUser(){
        const _this = this;
        if(!this.state.email || (this.state.email && EmailValidator.validate(this.state.email))) {
            if (!this.state.loading) {
                this.setState({success: false,loading: true},
                    ()=> {
                        this.props.editAccountAction(("_id" in this.state.user ? this.state.user._id : this.state.user.id), this.state.email, this.state.phone, this.state.user.status, this.state.role).then(res => {
                            _this.props.getUsersRestrictedAction();
                            _this.setState({success: true,loading: false}, ()=>{
                                _this.handleChangePage(0, null, true)
                            })
                        }).catch(err=> {
                            err.then(function(object) {
                                _this.setState({success: false, loading: false, errorEmail: object.message})
                            });
                        })
                    }
                );
            }
        }else{
            if(this.state.email && (this.state.email && !EmailValidator.validate(this.state.email))){
                this.setState({ errorEmail: "Adresse email non valide" });
            }
        }
    }

    deleteUser(user_id){
        const _this = this;
        if (user_id) {
            this.props.deleteUserAction(user_id).then(res => {
                _this.props.getUsersRestrictedAction();
                //console.log(res)
            }).catch(err=> {
                //console.log(err)
            })
        }
    }

    render(){
      return(

            <Members
                handleCloseError={this.handleCloseError}
                errorEmail={this.state.errorEmail}
                errorName={this.state.errorName}
                history={this.props.history}
                state={this.state}
                user={this.props.user}
                users={this.props.users}
                getMedia={getMedia}
                handleChangePage={this.handleChangePage}
                handleChangeFormData={this.handleChangeFormData}
                handleChangePhoneData={this.handleChangePhoneData}
                page={this.state.page}
                submitUser={this.submitUser}
                editUser={this.editUser}
                editAccountAction={this.props.editAccountAction}
                sendInvitation={this.sendInvitation}
                invitationSent={this.state.invitationSent}
                deleteUser={this.deleteUser}
            />
      )
    }
}

const mapStateToProps=(state)=>{
    return state
};

export default connect(mapStateToProps, actionCreators) (MembersCon);