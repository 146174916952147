import types from '../utils/params.js'

let defaultState={
    user: null,
    users: [],
    loading: false,
    header: {
        direction: null,
        position: 0
    },
    tweetits_draft: [],
    tweetits_suggested: [],
    isLoading_draft: true,
    isLoading_suggested: true,
    isAuthenticated: false
}
const mainReducer = (state=defaultState, action) => {
  switch(action.type) {

      case types.SET_HEADER: {
          return Object.assign({}, state, {
              header: {
                  direction: action.direction,
                  position:action.position
              },
          });
      }

      case types.UPDATE_LOADING: {
          return Object.assign({}, state, {
              loading: action.loading
          });
      }

      case types.UPDATE_USERS: {
          return Object.assign({}, state, {
              users: action.users
          });
      }

      case types.UPDATE_USER: {
          if(!state.user || (Object.keys(state.user).length === 0 && state.user.constructor === Object) || (Object.keys(action.user).length === 0 && action.user.constructor === Object) || action.user._id === state.user._id){
              return Object.assign({}, state, {
                  user: action.user
              });
          } else {
              return Object.assign({}, state, {
                  users: state.users.map(user => {
                      if(action.user && "_id" in action.user && user._id === action.user._id){
                          return action.user;
                      }else {
                          return user
                      }
                  })
              });
          }
      }

      case types.UPDATE_TWEETITS: {
          return Object.assign({}, state, {
              tweetits_draft: action.tweetits.filter(tw => tw.status === "draft"),
              tweetits_suggested: action.tweetits.filter(tw => tw.status === "suggested"),
          });
      }

      case types.UPDATE_DRAFT_TWEETITS: {
          return Object.assign({}, state, {
              tweetits_draft: action.tweetits,
              isLoading_draft: false
          });
      }

      case types.UPDATE_SUGGESTED_TWEETITS: {
          return Object.assign({}, state, {
              tweetits_suggested: action.tweetits,
              isLoading_suggested: false
          });
      }

      case types.REMOVE_TWEETIT: {
          return Object.assign({}, state, {
              tweetits_draft: state.tweetits_draft.filter(draft => draft._id !== action.tweetit_id),
              tweetits_suggested: state.tweetits_suggested.filter(suggested => suggested._id !== action.tweetit_id),
          });
      }

      case types.ADD_TWEETIT: {
          if(action.tweetit.status === "draft"){
              return Object.assign({}, state, {
                  tweetits_draft: [...state.tweetits_draft, action.tweetit],
              });
          }else if(action.tweetit.status === "suggested"){
              return Object.assign({}, state, {
                  tweetits_suggested: [...state.tweetits_suggested, action.tweetit],
              });
          }
          return null

      }

      case types.REPLACE_TWEETIT: {
          /* Supprime l'ancien tweetit */
          let obj = Object.assign({}, state, {
              tweetits_draft: state.tweetits_draft.filter(draft => draft._id !== action.old_tweetit_id),
              tweetits_suggested: state.tweetits_suggested.filter(suggested => suggested._id !== action.old_tweetit_id),
          })

          /* Ajout le nouvea */
          if(action.new_tweetit.status === "draft"){
              obj =  Object.assign({}, obj, {
                  tweetits_draft: [...obj.tweetits_draft, action.new_tweetit],
              });
          }else if(action.new_tweetit.status === "suggested"){
              obj =  Object.assign({}, obj, {
                  tweetits_suggested: [...obj.tweetits_suggested, action.new_tweetit],
              });
          }

          return obj;
      }

      case types.ADD_TWEETITS: {
          if(action.status === "draft"){
              return Object.assign({}, state, {
                  tweetits_draft: action.tweetits.concat(state.tweetits_draft),
              });
          }else if(action.status === "suggested"){
              return Object.assign({}, state, {
                  tweetits_suggested: action.tweetits.concat(state.tweetits_suggested),
              });
          }
          return null

      }

      case types.UPDATE_TWEETIT: {
          return Object.assign({}, state, {
              tweetits_draft: state.tweetits_draft.map(draft => {
                  if(draft._id === action.tweetit._id){
                      return Object.assign(draft, action.tweetit);
                  }else {
                      return draft
                  }
              })
          });
      }

    case types.UPDATE_ISAUTHENTICATED: {
      return Object.assign({}, state, {
          isAuthenticated: action.isAuthenticated
      });
    }

    default: return state;
  }
}

export default mainReducer;
