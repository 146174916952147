import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import windowSize from 'react-window-size';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ReactSVG from 'react-svg'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import CombinedShapeIcon from '../../assets/images/combined-shape.svg';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import sendIcon from '../../assets/images/send.svg';
import Tooltip from '@material-ui/core/Tooltip';
import StringReplace  from 'react-string-replace';
import Linkify from 'react-linkify';
import base64 from 'base64-arraybuffer';
import diff from 'deep-diff';
import AccountCircle from '@material-ui/icons/AccountCircle';
import moment from 'moment';
import 'moment/locale/fr';
import defaultUserIcon from "../../assets/images/default_profile_normal.png";
moment.locale('fr');

const styles = theme => ({
    card: {
        borderRadius: 5,
        backgroundColor: "#ffffff",
        boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.2)",
    },
    progress: {
        margin: "0 10px 0 0",
    },
    progressSquare: {
        margin: 0,
    },
    imgProgress: {
        margin: "auto",
    },
    imgProgressContainer: {
        width: "100%",
        height: 200,
        marginTop: 20,
        backgroundColor: "#e1f0f9",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
    },
    cardHeader: {
        padding: "25px 25px 0px 25px",
    },
    cardContent: {
        paddingLeft: 25,
        paddingRight: 25,
        paddingBottom: 0,
        paddingTop: 25,
        fontSize: 15,
        textAlign: "left",
        fontWeight: "normal",
        color: "#525252",
        "& p": {
            overflowWrap: "break-word",
        }
    },
    cardAction: {
        position: "relative",
        display: "block",
        padding: "30px 25px 25px 25px",
        justifyContent: "space-between",
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
    },
    date: {
        fontSize: 14,
        textAlign: "right",
        fontWeight: 600,
        color: "#525252",
    },
    manager: {
        fontSize: 13,
        textAlign: "right",
        fontWeight: 500,
        color: "#86a1b2",
    },
    chipCuration: {
        textTransform: "uppercase",
        float: "right",
        margin: "5px 0 0 0",
        borderRadius: 9.5,
        height: 19,
        width: 94,
        backgroundColor: "#ffa447",
        fontSize: 11,
        fontFamily: 'Hind Vadodara',
        color: "#ffffff",
        fontWeight: 700,
        '& span': {
            margin: "2px 12px 1px 7px",
            padding:0,
        }
    },
    chipCurationIcon: {
        color: "#ffffff",
        height: 15,
        padding: "0 0 0 10px",
    },
    chipDone: {
        textTransform: "uppercase",
        float: "right",
        margin: "5px 0 0 0",
        borderRadius: 9.5,
        height: 19,
        width: 70,
        backgroundColor: "#23e27f",
        fontSize: 11,
        fontFamily: 'Hind Vadodara',
        color: "#ffffff",
        fontWeight: 700,
        '& span': {
            margin: "2px 18px 1px 12px",
            padding:0,
        }
    },
    chipDoneIcon: {
        color: "#ffffff",
        height: 15,
        padding: "0 0 0 20px",
    },
    chipClear: {
        textTransform: "uppercase",
        float: "right",
        margin: "5px 0 0 0",
        borderRadius: 9.5,
        height: 19,
        width: 70,
        backgroundColor: "#f2615e",
        fontSize: 11,
        fontFamily: 'Hind Vadodara',
        color: "#ffffff",
        fontWeight: 700,
        '& span': {
            margin: "2px 19px 1px 9px",
            padding:0,
        }
    },
    chipClearIcon: {
        color: "#ffffff",
        height: 15,
        padding: "0 0 0 20px",
    },
    chipEdit: {
        textTransform: "uppercase",
        float: "right",
        margin: "5px 0 0 0",
        borderRadius: 9.5,
        height: 19,
        width: 117,
        backgroundColor: "#23e27f",
        fontSize: 11,
        fontFamily: 'Hind Vadodara',
        color: "#ffffff",
        fontWeight: 700,
        '& span': {
            margin: "2px 19px 1px 9px",
            padding:0,
        }
    },
    chipEditIcon: {
        color: "#ffffff",
        height: 15,
        padding: "0 0 0 20px",
    },
    avatarChip: {
        backgroundColor: "transparent",
    },
    avatar: {
        height: 30,
        width: 30,
        marginRight: -13,
        border: "solid 3px #ffffff",
    },
    avatars: {
        display: "flex",
    },
    leftIcon: {
        marginRight: 10,
        width: 16,
        height: 16,
    },
    leftIconRetweet: {
        marginRight: 10,
        float: "left",
        width: 21,
        height: 11,
    },
    buttonsColumn: {
        flexWrap: "wrap",
        flexFlow: "column-reverse",
        '& > button': {
            width: "100%",
            marginBottom: "10px",
        }
    },
    buttonSubmit: {
        height: 36,
        margin: 0,
        padding: "5px 16px",
        color: "#ffffff",
        boxShadow: "none",
        float: "right",
        '&:active': {
            boxShadow: "none",
        }
    },
    buttonEdit: {
        height: 36,
        margin: "0 10px 0 0",
        padding: "5px 16px",
        boxShadow: "none",
        borderRadius: 5,
        border: "solid 1px #dbdbdb",
        color: "#697e8b",
        fontWeight: 600,
        backgroundColor: "#ffffff",
        '&:active': {
            boxShadow: "none",
        }
    },
    buttonEditRight: {
        position: "absolute",
        right: 70,
    },
    buttonRemove: {
        height: 36,
        margin: 0,
        padding: "5px 16px",
        boxShadow: "none",
        borderRadius: 5,
        color: "#ffffff",
        '&:active': {
            boxShadow: "none",
        }
    },
    media: {
        height: 200,
        width: "100%",
        marginTop:20,
        objectFit: "cover",
        borderRadius: 5
    },
    retweet: {
        margin: "10px 0 0 0 !important",
        padding: "0 !important",
        borderRadius: 5,
        border: "solid 1px #dbdbdb",
        width: "100%",
    },
    retweetImage: {
        height: "auto",
        maxHeight: 100,
        width: "100%",
        maxWidth: 78,
        borderRadius: 5
    },
    retweetTitleCon: {
        padding: "10px !important",
        fontSize: 13,
        fontWeight: 600,
        color: "#525252",
    },
    retweetSubTitleCon: {
        padding: "10px !important",
        fontSize: 13,
        fontWeight: "normal",
        color: "#86a1b2",
    },
    retweetImageCon: {
        padding: "0 10px 10px 10px !important",
    },
    retweetMessageCon: {
        fontSize: 13,
        padding: "0 10px 10px 10px !important",
        fontWeight: "normal",
        color: "#525252",
        overflowWrap: "break-word",
    },
    refusalCon:{
        margin: "30px 0 0 0",
        width: "100%",
        borderRadius: 5,
        border: "solid 1px #f2615e",
    },
    refusalTitle:{
        padding: "15px 15px 5px 15px !important",
        "& p": {
            color: "#f2615e",
            fontSize: 11,
            fontWeight: 700,
            textTransform: 'uppercase'
        }
    },
    refusalMessage:{
        padding: "0 15px 15px 15px !important",
        "& p": {
            color: "#525252",
            fontSize: 13,
            fontWeight: "normal",
        }
    },
    square:{
        padding: 0,
        width: 36,
        minWidth: 36,
        height: 36,
        minHeight: 36,
    },
    tooltipCustom :{
        marginTop: "-10px !important",
    },
});

class TweetCard extends React.Component {
    state={
        submitted: false,
        old_props:null,
        img_loading: false,
    }

    componentWillReceiveProps(nextProps) {
        if(!this.state.old_props || (this.state.old_props && (diff(this.state.old_props.media, nextProps.media) || diff(this.state.old_props.url, nextProps.url) || diff(this.state.old_props.message, nextProps.message)))){
            if(nextProps.user && nextProps.media && nextProps.id) {
                this.getMedia(nextProps);
            }
        }
    }

    componentWillMount() {
        if(!this.state.old_props || (this.state.old_props && (diff(this.state.old_props.media, this.props.media) || diff(this.state.old_props.url, this.props.url) || diff(this.state.old_props.message, this.props.message)))){
            if (this.props.user && this.props.media && this.props.id)
                this.getMedia(this.props);
        }
    }

    getMedia(props){
        this.setState({img_loading: true},()=> {
            this.props.getMedia(props.media, props.id).then(res => {
                res.arrayBuffer().then(r => {
                    this.setState({
                        img_loading: false,
                        old_props: props,
                        img_src: "data:image/gif;base64," + base64.encode(r)
                    })
                }).catch(e => {
                    this.setState({img_loading: false, old_props: null})
                })
            }).catch(e => {
                this.setState({img_loading: false, old_props: props})
            })
        })
    }

    render() {
    const props = this.props;
    var tweetit_user = null;
    if('users' in props) {
        tweetit_user = props.users.find(u => u._id === props.user_id)
        if (!tweetit_user && props.user_id === props.user._id) {
            tweetit_user = props.user;
        }
    }
    return (
        <Card className={props.classes.card}>
            {this.props.showHeader && (
                <CardHeader
                    className={props.classes.cardHeader}
                    title={
                        <Grid container justify="center" spacing={16}>
                            <Grid xs={6} item className={props.classes.avatars}>
                                {props.childs.filter(c => c.user_id).map((child, index) => {
                                    return "profile_image_url_https" in child.user_id ? (
                                        <Tooltip key={index} classes={{ popper: props.classes.tooltipCustom }} title={"displayName" in child.user_id ? child.user_id.displayName : child.user_id.screen_name}><Avatar alt={child.user_id.displayName} src={"profile_image_url_https" in child.user_id ? child.user_id.profile_image_url_https : defaultUserIcon} style={{ zIndex: (index - 5) *-1 }} className={props.classes.avatar} /></Tooltip>
                                        ) : (
                                        <Tooltip key={index} classes={{ popper: props.classes.tooltipCustom }} title={"displayName" in child.user_id ? child.user_id.displayName : child.user_id.screen_name}><Avatar style={{ zIndex: (index - 5) *-1 }} className={props.classes.avatar}><AccountCircle/></Avatar></Tooltip>
                                    )
                                })}
                            </Grid>
                            <Grid xs={6} item className={props.classes.infos}>
                                {props.created_at !== "" && (
                                    <div className={props.classes.date}>
                                        {(((props.status === "draft" || props.status === "suggested") && tweetit_user) && <Typography className={props.classes.manager} >{"Par " + tweetit_user.screen_name}</Typography>)}
                                        <Typography className={props.classes.date} >
                                            {props.status === "draft" ? "Créé le " + moment(props.edited_at).format('Do MMM H:mm') : moment(props.edited_at).format('Do MMM H:mm') }
                                        </Typography>
                                    </div>
                                )}

                                {(function() {
                                    switch(props.status) {
                                        case 'published':
                                            return (
                                                <Chip
                                                    avatar={<Avatar className={props.classes.avatarChip}><DoneIcon className={props.classes.chipDoneIcon}/></Avatar>}
                                                    label="Publié"
                                                    className={props.classes.chipDone}
                                                />
                                            );
                                        case 'removed':
                                            return (
                                                <Chip
                                                    avatar={<Avatar className={props.classes.avatarChip}><ClearIcon className={props.classes.chipClearIcon}/></Avatar>}
                                                    label="Refusé"
                                                    className={props.classes.chipClear}
                                                />
                                            );
                                        case 'edited':
                                            return (
                                                <Chip
                                                    avatar={<Avatar className={props.classes.avatarChip}><EditIcon className={props.classes.chipEditIcon}/></Avatar>}
                                                    label="édité et publié"
                                                    className={props.classes.chipEdit}
                                                />
                                            );
                                        default:
                                            if(props.curation){
                                                return (
                                                    <Chip
                                                        avatar={<Avatar className={props.classes.avatarChip}><RemoveRedEyeIcon className={props.classes.chipCurationIcon}/></Avatar>}
                                                        label="Curation"
                                                        className={props.classes.chipCuration}
                                                    />
                                                );
                                            } else {
                                                return null;
                                            }
                                    }
                                })()}
                            </Grid>
                        </Grid>
                    }
                />
            )}
            {props.type === "tweet" ? (
                <CardContent className={props.classes.cardContent}>
                    <Typography component="p" >
                        <Linkify  properties={{target: '_blank'}}>
                            {StringReplace(StringReplace(props.message.replace(/(http|https).*status\/[0-9a-zA-Z]*$/i, ''), RegExp(/#(\S+\b)/ig), (match, i) => (
                                <a key={"#" + i} rel="noopener noreferrer" target='_blank' href={'https://twitter.com/hashtag/' + match + '?src=hash'}>#{match}</a>
                            )), RegExp(/@(\S+\b)/ig), (match2, j) => {
                                return <a key={"@" + match2} rel="noopener noreferrer" target='_blank' href={'https://twitter.com/' + match2}>@{match2}</a>
                            }) }
                        </Linkify>
                    </Typography>
                    {(this.props.citation && 'name' in this.props.citation) && (
                        <Grid container  className={props.classes.retweet} spacing={16}>
                            <Grid xs={4} item className={props.classes.retweetTitleCon}>
                                {this.props.citation && this.props.citation.name}
                            </Grid>
                            <Grid xs={8} item className={props.classes.retweetSubTitleCon}>
                                {(this.props.citation ) && "@" + this.props.citation.screen_name}
                            </Grid>
                            <Grid xs={12} item className={props.classes.retweetMessageCon}>
                                {this.props.citation.message}
                            </Grid>
                            <Grid xs={12} item className={props.classes.retweetMessageCon}>
                                {(this.props.citation.media_url_https && this.props.citation.media_url_https !== "") && <img alt={this.props.citation.name} src={this.props.citation.media_url_https} className={props.classes.media} style={{marginTop:0}}/>}
                            </Grid>
                        </Grid>
                    )}
                    {(this.props.media && this.state.img_src && !this.state.img_loading) && (
                            <img alt={this.props.media_alt} src={this.state.img_src} className={props.classes.media}/>
                    )}
                    {this.state.img_loading && (
                        <div className={this.props.classes.imgProgressContainer}>
                            <CircularProgress className={this.props.classes.imgProgress}  size={40} />
                        </div>
                    )}
                    {(this.props.status === "removed" && this.props.refusal_message) && (
                    <Grid container spacing={16} className={props.classes.refusalCon}>
                        <Grid xs={12} item className={props.classes.refusalTitle}>
                            <Typography>
                                {"Commentaire au gestionnaire"}
                            </Typography>
                        </Grid>
                        <Grid xs={12} item className={props.classes.refusalMessage}>
                            <Typography>
                                {this.props.refusal_message}
                            </Typography>
                        </Grid>
                    </Grid>
                    )}
                </CardContent>
            ) : (
                <CardContent className={props.classes.cardContent}>
                    <Typography component="span" style={{color: "#1ca1f2", fontSize: 15, fontWeight: 600,}}>
                    <ReactSVG src={CombinedShapeIcon}/>
                    </Typography>
                    <Grid container  className={props.classes.retweet} spacing={16}>
                        <Grid xs={4} item className={props.classes.retweetTitleCon}>
                            {(this.props.tweet_ref && this.props.tweet_ref.user ) && this.props.tweet_ref.user.name}
                        </Grid>
                        <Grid xs={8} item className={props.classes.retweetSubTitleCon}>
                            {(this.props.tweet_ref && this.props.tweet_ref.user ) && "@" + this.props.tweet_ref.user.screen_name}
                        </Grid>
                        {(this.props.media && this.state.img_src && !this.state.img_loading) && (
                            <Grid xs={4} item className={props.classes.retweetImageCon}>
                                <img alt={this.props.media_alt} src={this.state.img_src} className={props.classes.retweetImage}/>
                            </Grid>
                        )}
                        {this.state.img_loading && (
                            <Grid xs={4} item className={this.props.classes.imgProgressContainer}>
                                <CircularProgress className={this.props.classes.imgProgress}  size={20} />
                            </Grid>
                        )}
                        <Grid xs={this.props.media ? 8 : 12} item className={props.classes.retweetMessageCon}>
                            <Linkify  properties={{target: '_blank'}}>
                            {this.props.message ? this.props.message : this.props.url}
                            </Linkify>
                        </Grid>
                    </Grid>
                </CardContent>
            )}
            {(this.props.isEditable || this.props.isSubmitable) && (
            <CardActions className={props.classes.cardAction + " " + (this.props.windowWidth < 300 && props.classes.buttonsColumn)}>
                {this.props.isRemovable && (
                    <Button variant="contained" color="secondary" className={[props.classes.buttonRemove, props.classes.square]} onClick={() => this.props.handleRemove({
                        id: this.props.id,
                        media: this.props.media,
                        status: this.props.status,
                        media_alt: this.props.media_alt,
                        message: this.props.message,
                        type: this.props.type,
                        url: this.props.url,
                        childs: this.props.childs,
                        tweet_ref: this.props.tweet_ref,
                    })}>
                        <ClearIcon style={{height: 18}}  />
                    </Button>
                )}
                {this.props.isEditable && (
                    <Button variant="contained" className={this.props.editLabel ? [props.classes.buttonEdit, this.props.isRemovable && props.classes.buttonEditRight] : [props.classes.buttonEdit, props.classes.square, this.props.isRemovable && props.classes.buttonEditRight]} onClick={() => this.props.handleEdit({
                        id: this.props.id,
                        media: this.props.media,
                        status: this.props.status,
                        media_alt: this.props.media_alt,
                        message: this.props.message,
                        type: this.props.type,
                        url: this.props.url,
                        childs: this.props.childs,
                        tweet_ref: this.props.tweet_ref,
                    })}>
                        <EditIcon style={{height: 18}} className={(this.props.editLabel && (this.props.windowWidth > 370 || this.props.windowWidth < 300)) ? props.classes.leftIcon : ""}/>
                        {(this.props.windowWidth > 370 || this.props.windowWidth < 300) && this.props.editLabel}
                    </Button>
                )}
                {this.props.isSubmitable && (
                    <Button variant="contained" color="primary" disabled={this.state.submitted} className={this.props.submitLabel ? props.classes.buttonSubmit : [props.classes.buttonSubmit, props.classes.square]} onClick={this.props.handleSubmit ? () => this.setState({submitted: true}, then => {this.props.handleSubmit(this.props.id, {
                        message: this.props.message,
                        media: this.props.media,
                        media_alt: this.props.media_alt,
                        status: "suggested",
                        type: this.props.type,
                        url: this.props.url,
                        users: this.props.childs.map(child => child.user_id._id),
                        tweet_ref: this.props.tweet_ref,
                    })}): () => this.setState({submitted: true})}>
                        {this.state.submitted ?  (
                            <CircularProgress className={this.submitLabel ? this.props.classes.progress : this.props.classes.progressSquare}  size={20} />
                        ) : (
                            <ReactSVG src={sendIcon} style={{height: 16}} className={(this.props.submitLabel && (this.props.windowWidth > 370 || this.props.windowWidth < 300)) ? props.classes.leftIcon : "" }/>
                            )}
                        {(this.props.windowWidth > 370 || this.props.windowWidth < 300) && this.props.submitLabel}
                    </Button>
                )}
            </CardActions>
            )}
        </Card>
    );
    }
}

TweetCard.propTypes = {
    classes: PropTypes.object.isRequired,
    childs: PropTypes.array.isRequired,
    message: PropTypes.string.isRequired,
    status: PropTypes.oneOf([
        'draft', 'suggested', 'published', 'edited', 'removed', null
    ]),
    type:PropTypes.oneOf([
        'tweet', 'retweet'
    ]),
    curation: PropTypes.bool.isRequired,
    isSubmitable: PropTypes.bool.isRequired,
    showHeader: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isRemovable: PropTypes.bool.isRequired,
    created_at: PropTypes.string.isRequired,
    edited_at: PropTypes.string.isRequired,
};

export default withStyles(styles)(windowSize(TweetCard));