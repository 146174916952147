import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
/*import MoreIcon from '@material-ui/icons/MoreVert';*/
import logo from '../assets/images/logo.png';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import defaultUserIcon from "../assets/images/default_profile_normal.png";

//import TopMenu from './misc/top-menu.js';

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: "#ffffff !important",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 600,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        zIndex: 2,
    },
    username: {
        fontSize: 14,
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 600,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#525252",
    },
    appBar: {
        backgroundColor: "#ffffff !important",
        boxShadow: "none",
    },
    toolBar: {
        height: 79,
        maxWidth: 1366,
        width:"100%",
        margin: "0 auto",
        padding: 0,
        backgroundColor: "#ffffff",
    },
    gridBar: {
        height: 79,
        padding: "0 20px",
        [theme.breakpoints.up('md')]: {
            padding: "0 83px",
        },
    },
    gridTopMenu: {
        height: 79,
    },
    avatar: {
        width: 46,
        height: 46,
        marginLeft: 15,
    },
    avatarButton: {
        padding: 0,
        float: "right"
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    title: {
        display: 'block',
        /*[theme.breakpoints.up('sm')]: {
            display: 'block',
        },*/
    },
    navLink: {
        fontSize: 15,
        fontWeight: 600,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "center",
        textDecoration: "none",
        color: "#697e8b",
        margin: 0,
        width: "100%",
        height: "100%",
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    sectionMobile: {
        float:"right",
       display: 'block',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    menuItem: {
       textAlign: "center",
       paddingLeft: "0 !important",
       paddingRight: "0 !important",
        fontSize: "15px !important",
        color: "#697e8b !important"
    }
});

class Header extends React.Component {
    state = {
        anchorEl: null,
        mobileMoreAnchorEl: null,
    };

    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    render() {
        const { anchorEl, mobileMoreAnchorEl } = this.state;
        const { classes } = this.props;
        const isMenuOpen = Boolean(anchorEl);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

        const renderMenu = (
            <Menu
                className="popover_class"
                PaperProps={{
                    style: {
                        marginLeft: -50,
                        marginTop: -10,
                        width: 200,
                        textAlign: "center",
                        borderRadius: 5,
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        overflow: "visible",
                    },
                }}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                getContentAnchorEl={null}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                {/*<MenuItem onClick={this.handleMobileMenuClose}><NavLink exact to="/account" className={classes.navLink}>Mon profil</NavLink></MenuItem>
                <MenuItem onClick={this.handleMobileMenuClose}><NavLink exact to="/" className={classes.navLink}>Dashboard</NavLink></MenuItem>
                <MenuItem onClick={this.handleMobileMenuClose}><NavLink exact to="/members" className={classes.navLink}>Membres</NavLink></MenuItem>*/}
                <MenuItem onClick={this.props.logout}><ListItemText className={classes.menuItem} inset primary="Déconnexion" /></MenuItem>
            </Menu>
        );

        const renderMobileMenu = (
            <Menu
                PaperProps={{
                    style: {
                        width: 200,
                        textAlign: "center",
                    },
                }}
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                getContentAnchorEl={null}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                {/*<MenuItem onClick={this.handleMobileMenuClose}><NavLink exact to="/" className={classes.navLink}>Dashboard</NavLink></MenuItem>
                <MenuItem onClick={this.handleMobileMenuClose}><NavLink exact to="/members" className={classes.navLink}>Membres</NavLink></MenuItem>
                <MenuItem onClick={this.handleMobileMenuClose}><NavLink exact to="/stats" className={classes.navLink}>Statistiques</NavLink></MenuItem>
                <MenuItem onClick={this.handleMobileMenuClose}><NavLink exact to="/settings" className={classes.navLink}>Paramètres</NavLink></MenuItem>*/}
                <MenuItem onClick={this.props.logout}><ListItemText className={classes.menuItem} inset primary="Déconnexion" /></MenuItem>
            </Menu>
        );
        return (
                <div className={classes.root + " header"} >
                    <AppBar position="static" className={classes.appBar} >
                        <Toolbar className={classes.toolBar} >
                            <Grid container
                                  className={classes.gridBar}
                                  direction="row"
                                  justify="center"
                                  alignItems="center"
                            >

                                <Grid item xs={3}>
                                    <img onClick={this.props.scrollToTop} className={classes.logo} alt="logo" src={logo} />
                                </Grid>

                                <Grid item xs={6} className={classes.gridTopMenu}>
                                    {/*<div className={classes.sectionDesktop}>
                                    <TopMenu history={this.props.history}/>
                                    </div>*/}
                                </Grid>

                                <Grid item xs={3}>

                                    <div >

                                        <IconButton
                                            aria-owns={isMenuOpen ? 'material-appbar' : null}
                                            aria-haspopup="true"
                                            disableRipple
                                            onClick={this.props.user.role === "User" ? this.handleMobileMenuOpen : this.handleProfileMenuOpen}
                                            color="inherit"
                                            className={classes.avatarButton + " noOver"}
                                        >
                                            {this.props.user.role !== "User" && (
                                                <Typography className={classes.username}>
                                                    {"displayName" in this.props.user ? this.props.user.displayName : this.props.user.screen_name}
                                                </Typography>
                                            )}
                                            <Avatar
                                                alt={"displayName" in this.props.user ? this.props.user.displayName : this.props.user.screen_name}
                                                src={"profile_image_url_https" in this.props.user ? this.props.user.profile_image_url_https : defaultUserIcon}
                                                className={classes.avatar}
                                            />
                                        </IconButton>
                                    </div>
                                    {/*<div className={classes.sectionMobile}>
                                        <IconButton aria-haspopup="true" className={" noOver"} onClick={this.handleMobileMenuOpen} >
                                            <MoreIcon />
                                        </IconButton>
                                    </div>*/}
                                </Grid>

                            </Grid>


                        </Toolbar>
                    </AppBar>
                    {renderMenu}
                    {renderMobileMenu}
                </div>
        );
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);