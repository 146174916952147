import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import TwitterLogin from "react-twitter-auth";
import Grid from '@material-ui/core/Grid';
import logo from '../assets/images/logo.png';
import twitter_logo from '../assets/images/twitter.png';
//import Button from '@material-ui/core/Button';
import Constants from '../utils/params';
import Typography from '@material-ui/core/Typography';
//import Form from 'react-validation/build/form';
//import Input from 'react-validation/build/input';
//import validator from 'validator';
import '../assets/css/signIn.css'

const styles = theme => ({
    root: {
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: 'flex', // Fix IE11 issue.
    },
    layout: {
        backgroundColor: '#ffffff',
        maxHeight: 500,
        height: "100%",
        maxWidth: 552,
        width: "100%",
        borderRadius: 5
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 5,
        padding: 50,
        maxWidth: 552,
        margin: "0 auto",
        backgroundColor: '#ffffff',
        boxShadow: 'none',
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 600,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    logo: {
        marginBottom: 50
    },
    form: {
        width: '100%', // Fix IE11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    leftIcon: {
        marginRight: 10,
    },
    twitterButton: {
        color: "#fff",
        borderRadius: 4,
        margin: 8,
        padding: "8px 16px",
        border: "none",
        height: 36,
        width: 328,
        backgroundColor: "#2196f3",
        lineHeight: "25px",
        fontFamily: "Open Sans",
        fontWeight: 700,
        fontStyle: "normal",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        "& p": {
            color: "#fff",
        }
    }
});

/*const required = (value) => {
    if (!value.toString().trim().length) {
        // We can return string or jsx as the 'error' prop for the validated Component
        return 'require';
    }
};

const email = (value) => {
    if (!validator.isEmail(value)) {
        return `${value} is not a valid email.`
    }
};*/

class SignIn extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container
                      justify="center"
                      alignItems="center"
                        className={classes.layout}
                >
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                            <img className={classes.logo} alt="logo" src={logo} />

                            <TwitterLogin loginUrl={Constants.DOMAIN+"/auth/twitter"}
                                          onFailure={this.props.onFailed} onSuccess={this.props.onSuccess}
                                          showIcon={true}
                                          className={classes.twitterButton}
                                          requestTokenUrl={Constants.DOMAIN+"/auth/twitter/reverse"}>
                                <img alt="twitter_icon" src={twitter_logo} className={classes.leftIcon} />
                                <Typography>
                                    Se connecter à Twitter
                                </Typography>
                            </TwitterLogin>
                            {this.props.error && (
                                <Typography color="secondary">
                                Une erreur s'est produite
                                </Typography>
                            )}
                            {/*<Typography>
                                OU
                            </Typography>
                            <Form ref={c => { this.form = c }} onSubmit={this.props.handleLoginSubmit}>
                                <h3>Login</h3>
                                <div>
                                    <label>
                                        Email*
                                        <Input value={this.props.email} onChange={this.props.handleChange} name='email' validations={[required, email]}/>
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        Password*
                                        <Input type={this.props.password} onChange={this.props.handleChange} name='password' validations={[required]}/>
                                    </label>
                                </div>
                                <div>
                                    <Button type="submit">Submit</Button>
                                </div>
                            </Form>*/}
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );

    };
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);