import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';

class ConfirmDialog extends React.Component {

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {/*<DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>*/}
                    <DialogContent style={{
                        padding: "50px 107px 0 107px",
                        textAlign: "center",
                    }}>
                        <DialogContentText id="alert-dialog-description">
                            {this.props.message.split('\n').map((item, key) => {
                                return <span key={key} style={{
                                    color: "#525252",
                                    fontWeight: 600,
                                    fontSize: 15,
                                }}>{item}<br/></span>
                            })}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{
                        justifyContent: "center",
                        paddingTop: 50,
                        paddingBottom: 50,
                        margin: 0,
                    }}>
                        <Button onClick={() => this.props.handleCancel()} variant="outlined" >
                            Annuler
                        </Button>
                        <Button onClick={() => this.props.handleValid()}  style={{backgroundColor:"#f2615e",marginLeft:20,}} variant="contained" color="secondary" autoFocus>
                            Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ConfirmDialog;