import React from 'react';
import {connect} from "react-redux";
import * as actionCreators from "../actions/actionCreators"
import Settings from "../components/settingsUser.js";

class SettingsCon extends React.Component{

    state = {
        smsChecked: true,
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    render(){
        return(
            <Settings history={this.props.history} user={this.props.user} handleChange={this.handleChange} smsChecked={this.state.smsChecked}/>
        )
    }
}

const mapStateToProps=(state)=>{
    return state
};

export default connect (mapStateToProps, actionCreators)(SettingsCon);
