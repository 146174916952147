import React from 'react';
import {connect} from "react-redux";
import * as actionCreators from "../actions/actionCreators"
import Header from "../components/header.js";

class HeaderCon extends React.Component{

    logout = async () => {
        await this.props.updateUserAction({});
        await this.props.updateUsersAction([]);
        this.props.updateIsAuthenticated(false);
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('key')
    };

    render(){
        return(
            <Header history={this.props.history} logout={this.logout} user={this.props.user} header={this.props.header} scrollToTop={this.props.scrollToTop}/>
        )
    }
}

const mapStateToProps=(state)=>{
    return state
};

export default connect (mapStateToProps, actionCreators)(HeaderCon);
