import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Datatable from "./misc/datatable.js";
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import clearIcon from "../assets/images/clear-icon.svg";
import validateIcon from "../assets/images/validate-icon.svg";
import ReactSVG from "react-svg";
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import ReactPhoneInput from 'react-phone-input-2';
import defaultUserIcon from "../assets/images/default_profile_normal.png";

const styles = theme => ({
    root: {
        maxWidth: 1366,
        margin: "0 auto",
        width: "100%",
    },
    rootGrid: {
        flexGrow: 1,
        //position: "relative",
        margin: 0,
        top: 0,
        marginTop: 30,
        width: "100%",
        //maxWidth: 1200,
        overflow: "auto",
        padding: "0 10px 0 20px",
        [theme.breakpoints.up('md')]: {
            padding: "0 59px",
        },
    },
    subGrid: {
        padding: "10px 0 20px 0 !important"
    },
    title: {
        padding: "0",
        marginBottom: 20,
        fontSize: 30,
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 500,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#697e8b",
    },
    button: {
        margin: 0,
        textTransform: "initial",
        textAlign: "center",
        fontSize: 15,
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 500,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#ffffff",
        height: 40,
    },
    link:{
        fontSize: 13,
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 500,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#86a1b2",
        textDecoration: "underline",
        marginTop: 5,
        cursor: "pointer",
    },
    linkDisabled: {
        fontSize: 13,
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 500,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#c7dae6",
        textDecoration: "underline",
        marginTop: 5,
        cursor: "not-allowed",
    },
    buttonCancel: {
        marginLeft: 20,
        height: 40,
        textTransform: "initial",
    },
    textField: {
        marginLeft: 0,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    form: {
        padding: 40,
    },
    bootstrapRoot: {
        'label + &': {
            marginTop: theme.spacing.unit * 3,
        },
    },
    bootstrapFormLabel: {
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 600,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#525252",
        marginBottom: 10,
        fontSize: 15,
        transform: "none",
    },
    bootstrapInput: {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 12px',
        height: "100%",
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        /*'&:focus': {
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },*/
    },
    listItem: {
        display: "flex",
        marginBottom: 10,
        "& p": {
            marginLeft: 10,
        },
    },
    listItemTitle: {
        margin: "10px 0",
    },
    margin: {
        width: "100%",
        maxWidth: 330,
        margin: "0 0 30px 0",
    },
    buttonProgress: {
        color: "#ffffff",
        marginRight: 10,
    },
    wrapper: {
        margin: 0,
        float: 'left',
    },
    buttonSuccess: {
        backgroundColor: "#ffffff",
        '&:hover': {
            backgroundColor: "#ffffff",
        },
    },
    subtitle: {
        color: "#86a1b2",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 500,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        fontSize: 13,
    },
});

function List(props) {
    const { classes } = props;
    return (
        <Grid item xs={12} className={classes.subGrid}>
        <Typography variant="headline" component="h2" className={classes.title}>
            Gérer les membres
        </Typography>

        <Datatable handleChangePage={props.handleChangePage} deleteUser={props.deleteUser} editAccountAction={props.editAccountAction}
               user={{ id: props.user._id, phone: props.user.phone, avatar: ("profile_image_url_https" in props.user ? props.user.profile_image_url_https : defaultUserIcon), screen_name: props.user.screen_name, display_name: ("displayName" in props.user ? props.user.displayName : null), email: props.user.email, account: "twitter", role: props.user.role, group: ".", status: props.user.status}}
                   users={props.users.map(user => {
            return { id: user._id, phone: user.phone, avatar: ("profile_image_url_https" in user ? user.profile_image_url_https : defaultUserIcon), screen_name: user.screen_name, display_name: ("displayName" in user ? user.displayName : null), email: user.email, account: "twitter", role: user.role, group: ".", status: user.status}
        })}/>
        </Grid>
    );
}

const roles = [
    {
        value: 'User',
        label: 'Membre',
    },
    {
        value: 'Manager',
        label: 'Gestionnaire',
    },
];

function Create(props) {
    const { classes } = props;
    return (
        <Grid item xs={12} className={classes.subGrid}>

            <Typography variant="headline" component="h2" className={classes.title}>
                Nouveau membre
            </Typography>

            <Paper className={classes.root} elevation={1}>
                <form className={classes.form} noValidate autoComplete="off">
                    <Grid container >
                        <Grid item xs={12}>
                            <FormControl error={props.errorName ? true : false} className={classes.margin}>
                                <InputLabel shrink htmlFor="bootstrap-input" className={classes.bootstrapFormLabel}>
                                    Compte Twitter
                                </InputLabel>
                                <InputBase
                                    label="Compte"
                                    id="screen_name"
                                    name="screen_name"
                                    value={props.state.screen_name}
                                    onChange={props.handleChangeFormData}
                                    placeholder={"moncomptetwitter"}
                                    classes={{
                                        root: classes.bootstrapRoot,
                                        input: classes.bootstrapInput,
                                    }}
                                />
                                <FormHelperText id="component-error-text">{props.errorName}</FormHelperText>
                                <Typography className={classes.subtitle}>
                                    Indiquer le nom d'utilisateur Twitter (sans @).
                                </Typography>
                                <Typography className={classes.subtitle}>
                                    Cela permettra d’autoriser ce compte à se connecter.
                                </Typography>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={props.errorEmail ? true : false} className={classes.margin}>
                                <InputLabel shrink htmlFor="bootstrap-input" className={classes.bootstrapFormLabel}>
                                    Inviter le membre par email (optionnel)
                                </InputLabel>
                                <InputBase
                                    label="Email"
                                    id="email"
                                    name="email"
                                    value={props.state.email}
                                    onChange={props.handleChangeFormData}
                                    placeholder={" mon@email.com"}
                                    classes={{
                                        root: classes.bootstrapRoot,
                                        input: classes.bootstrapInput,
                                    }}
                                />
                                <FormHelperText id="component-error-text">{props.errorEmail}</FormHelperText>
                            </FormControl>
                        </Grid>
                        {(props.state.role !== "Manager") && (
                            <Grid item xs={12}>
                                <FormControl className={classes.margin}>
                                    <Typography className={classes.bootstrapFormLabel}>
                                        Notifications SMS (optionnel)
                                    </Typography>
                                    <ReactPhoneInput
                                        label="Numéro de téléphone"
                                        id="phone"
                                        name="phone"
                                        value={props.state.phone}
                                        onChange={props.handleChangePhoneData}
                                        defaultCountry='fr'
                                        disableDropdown={true}
                                        inputStyle={{paddingLeft: 20}}
                                    />
                                    <Typography className={classes.subtitle} style={{marginTop: 10}}>
                                        Les membres recevront les notifications SMS dès qu’un nouveau tweet leur sera soumis.
                                    </Typography>
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <FormControl>
                                <Typography className={classes.bootstrapFormLabel}>
                                    Rôle
                                </Typography>
                                <Select
                                    label="Rôle"
                                    name="role"
                                    className={classNames(classes.margin, classes.textField)}
                                    style={{minWidth: 250}}
                                    value={props.state.role}
                                    onChange={props.handleChangeFormData}
                                    disableUnderline
                                    classes={{
                                        root: classes.bootstrapRoot,
                                        select: classes.bootstrapInput,
                                    }}
                                >
                                    {roles.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.listItemTitle}>
                                Le membre peut :
                            </Typography>
                            <div className={classes.listItem}>
                                <ReactSVG src={props.state.role === "Manager" ? clearIcon : validateIcon} />
                                <Typography>
                                    Recevoir et publier des contenus
                                </Typography>
                            </div>
                            <div className={classes.listItem}>
                                <ReactSVG src={props.state.role === "User" ? clearIcon : validateIcon} />
                                <Typography>
                                    Créer des brouillons
                                </Typography>
                            </div>
                            <div className={classes.listItem}>
                                <ReactSVG src={props.state.role === "User" ? clearIcon : validateIcon} />
                                <Typography>
                                    Soumettre des contenus
                                </Typography>
                            </div>
                            <div className={classes.listItem}>
                                <ReactSVG src={props.state.role === "User" ? clearIcon : validateIcon} />
                                <Typography>
                                    Gérer les membres
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: 40}}>
                            <div className={classes.wrapper}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    disabled={props.state.loading}
                                    onClick={props.submitUser}
                                >
                                    {props.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    Sauvegarder et envoyer une invitation
                                </Button>
                            </div>
                            <Button variant="outlined" className={classes.buttonCancel} onClick={()=>props.handleChangePage(0, null, true)}>
                                Annuler
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>


        </Grid>
    );
}

function Edit(props) {
    const { classes } = props;
    return (
        <Grid item xs={12} className={classes.subGrid}>

            <Typography variant="headline" component="h2" className={classes.title}>
                Editer le membre
            </Typography>

            <Paper className={classes.root} elevation={1}>
                <form className={classes.form} noValidate autoComplete="off">
                    {props.state.user ? (
                        <Grid container >
                            <Grid item xs={12}>
                                <FormControl error={props.errorEmail ? true : false} className={classes.margin}>
                                    <InputLabel shrink htmlFor="bootstrap-input" className={classes.bootstrapFormLabel}>
                                        Email
                                    </InputLabel>
                                    <InputBase
                                        label="Email"
                                        id="email"
                                        name="email"
                                        value={props.state.email ? props.state.email : ""}
                                        onChange={props.handleChangeFormData}
                                        classes={{
                                            root: classes.bootstrapRoot,
                                            input: classes.bootstrapInput,
                                        }}
                                    />
                                    <Typography className={props.invitationSent ? classes.linkDisabled : classes.link} onClick={props.sendInvitation}>
                                        Renvoyer l'invitation par email
                                    </Typography>
                                    <FormHelperText id="component-error-text">{props.errorEmail}</FormHelperText>

                                </FormControl>
                            </Grid>
                            {(props.state.role !== "Manager") && (
                                <Grid item xs={12}>
                                    <FormControl className={classes.margin}>
                                        <Typography className={classes.bootstrapFormLabel}>
                                            Numéro de téléphone
                                        </Typography>
                                        <ReactPhoneInput
                                            label="Numéro de téléphone"
                                            id="phone"
                                            name="phone"
                                            value={props.state.phone ? props.state.phone : ""}
                                            onChange={props.handleChangePhoneData}
                                            defaultCountry='fr'
                                            inputStyle={{paddingLeft: 20}}
                                            regions={'europe'}
                                        />
                                    </FormControl>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <FormControl disabled={props.user._id === props.state.user.id}>
                                    <Typography className={classes.bootstrapFormLabel}>
                                        Rôle
                                    </Typography>
                                    <Select
                                        label="Rôle"
                                        name="role"
                                        className={classNames(classes.margin, classes.textField)}
                                        style={{minWidth: 250}}
                                        value={props.state.role}
                                        onChange={props.handleChangeFormData}
                                        disableUnderline
                                        classes={{
                                            root: classes.bootstrapRoot,
                                            select: classes.bootstrapInput,
                                        }}
                                    >
                                        {roles.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.listItemTitle}>
                                    Le membre peut :
                                </Typography>
                                <div className={classes.listItem}>
                                    <ReactSVG src={props.state.role === "Manager" ? clearIcon : validateIcon} />
                                    <Typography>
                                        Recevoir et publier des contenus
                                    </Typography>
                                </div>
                                <div className={classes.listItem}>
                                    <ReactSVG src={props.state.role === "User" ? clearIcon : validateIcon} />
                                    <Typography>
                                        Créer des brouillons
                                    </Typography>
                                </div>
                                <div className={classes.listItem}>
                                    <ReactSVG src={props.state.role === "User" ? clearIcon : validateIcon} />
                                    <Typography>
                                        Soumettre des contenus
                                    </Typography>
                                </div>
                                <div className={classes.listItem}>
                                    <ReactSVG src={props.state.role === "User" ? clearIcon : validateIcon} />
                                    <Typography>
                                        Gérer les membres
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} style={{marginTop: 40}}>
                                <div className={classes.wrapper}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        disabled={props.state.loading}
                                        onClick={props.editUser}
                                    >
                                        {props.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                        Sauvegarder
                                    </Button>
                                </div>
                                <Button variant="outlined" className={classes.buttonCancel} onClick={()=>props.handleChangePage(0, null, true)}>
                                    Annuler
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <Typography variant="headline" component="h2" className={classes.title}>
                            Utilisateur inconnu
                        </Typography>
                    )}
                </form>
            </Paper>


        </Grid>
    );
}

class Members extends React.Component {

    render() {
        const props = this.props;
        const { classes, page } = props;
        return (
            <div className={classes.root}>
                <Grid container className={classes.rootGrid} justify="space-between">
                    {(function() {
                        switch(page) {
                            case 0:
                                return <List {...props}/>
                            case 1:
                                return <Create {...props}/>
                            case 2:
                                return <Edit {...props}/>
                            default:
                                return null;
                        }
                    })()}
                </Grid>
            </div>
        );
    }
}

Members.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Members);