import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowBackIcon from '../../assets/images/arrowDark.png';
import Slide from '@material-ui/core/Slide';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from "@material-ui/core/TextField/TextField";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const styles = theme => ({
    appBar: {
        position: 'relative',
        backgroundColor: "#ffffff",
        boxShadow: "none",
    },
    rootGrid: {
        padding: "0 25px 30px 25px",
        margin: 0,
        width: "100%",
    },
    flex: {
        flex: 1,
    },
    title:{
        fontSize: 20,
        fontWeight: 700,
        color: "rgb(134, 161, 178)",
    },
    actions:{
        justifyContent: "space-between",
        padding:"25px 25px 30px 25px",
        height: 36,
        minHeight: 36,
    },
    leftIcon: {
        marginRight: 10,
        width: 16,
        height: 16,
    },
    textField: {
        backgroundColor: "#ffffff",
        width: "100%",
        margin: "34px 0 11px 0",
        borderRadius: 10,
        "& fieldset": {
            borderRadius: 10,
            border: "solid 1px #c3d0d7",
        },
        "& input": {
            padding: "18.5px 35px 18.5px 14px",
        }
    },
    buttonSubmit: {
        height: 36,
        margin: 0,
        padding: "5px 16px",
        color: "#ffffff",
        textTransform: "Capitalize",
        boxShadow: "none",
        float: "right",
        '&:active': {
            boxShadow: "none",
        }
    },
    buttonEdit: {
        height: 36,
        margin: 0,
        padding: "5px 16px",
        boxShadow: "none",
        borderRadius: 5,
        border: "solid 1px #dbdbdb",
        color: "#697e8b",
        fontWeight: 600,
        backgroundColor: "#ffffff",
        textTransform: "Capitalize",
        '&:active': {
            boxShadow: "none",
        },
        '& img': {
            padding: "0 9px 0 0",
        }
    },
    tweetCount: {
        position: "absolute",
        right: 40,
        top: 345,
        color: "#23e27f",
        fontWeight: 600,
        fontSize: 12,
    },
    checkCircle: {
        position: "absolute",
        color: "#23e27f",
        width: 19,
        height: 19,
        right: 37,
        top: 171,
    },
    input: {
        display: 'none',
    },
    media: {
        marginTop: 10,
        width: "100%",
        height: 200,
        objectFit: "cover",
        borderRadius: 5,
        border: "1px solid rgb(219, 219, 219)",
    },
    buttonRemoveMedia: {
        position: "absolute",
        right: 0,
        top: 10,
        '& rect': {
            fill: "#525252",
        }
    },
    progress: {
        margin: "0 10px 0 0",
    },
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class FullScreenRefusalDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            refusal_message: "",
            posting:false,
        };
    }

    handleChange = event => {
        this.setState({refusal_message: event.target.value});
    };

    editTweetit(e, status) {
        this.setState({posting: true}, then => {
            this.props.editTweetitUserAction(this.props.formData.id, {
                refusal_message: this.state.refusal_message,
                status: "removed",
            }).then(res => {
                this.setState({
                    refusal_message: "",
                }, res => {
                    this.props.openSnackbar("Le tweet a bien été refusé.");
                    this.props.getTweetitsUserAction(this.props.tweetits.filter(t => t.status === "suggested").length);
                    this.props.handleClose();
                    this.setState({posting: false})
                })
            })
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog
                    fullScreen
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar className={classes.actions}>
                            <Button variant="contained" size="small" className={classes.buttonEdit} onClick={this.props.handleClose}>
                                <img alt={"btn_retour"} src={ArrowBackIcon} />
                                Retour
                            </Button>
                            <Button variant="contained" color="secondary" className={classes.buttonSubmit} disabled={this.state.posting} onClick={(e) => this.editTweetit(e, "removed")}>
                                {this.state.posting ? (
                                    <CircularProgress className={this.props.classes.progress}  size={20} />
                                ) : (
                                    <ClearIcon className={classes.leftIcon }/>
                                )}
                                {"Refuser"}
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Grid container className={classes.rootGrid} spacing={16} >
                        <Grid item xs={12} style={{padding: 0}} >
                            <Typography className={classes.title}>
                                {"Refuser le tweet"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{padding: 0}} >
                            <TextField
                                id="outlined-multiline-flexible"
                                label=""
                                multiline={true}
                                rows="10"
                                placeholder="Ecrire un commentaire (optionnel)"
                                rowsMax="10"
                                value={this.state.refusal_message}
                                onChange={this.handleChange}
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>
                        </Grid>

                </Dialog>
            </div>
        );
    }
}

FullScreenRefusalDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullScreenRefusalDialog);