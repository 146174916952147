import React from 'react';
import {connect} from "react-redux";
import * as actionCreators from "../actions/actionCreators";
import Home from "../components/homeManager.js";
import TimerMixin  from 'react-timer-mixin';
import reactMixin from 'react-mixin';
import hash from 'object-hash';
import {getMedia} from '../utils/api-request'

class HomeCon extends React.Component{
    constructor(props) {
        super(props);
        this.loadMoreTweetits = this.loadMoreTweetits.bind(this);
        this.scrollDown = this.scrollDown.bind(this);

        this.state = {
            dialogIsOpen: false,
            formData: {},
            lastDraftsTweetitsLength: 0,
            hasMoreDraft: true,
            lastSuggestedTweetitsLength: 0,
            hasMoreSuggested: true,
            loadingMore: true,
            scrollEnd: 0,
            loadingMoreDraft: false,
            loadingMoreSuggested: false,
            loadingMorePublished: false,
        };
    }


    componentWillMount() {
        this.props.getTweetitsAction(10, ["draft"]).then(()=>{
            this.props.getTweetitsAction(10, ["suggested"]).then(()=>{
                this.setState({loadingMore: false})
            });
        });
        this.props.getUsersRestrictedAction();

        /* Auto refresh */
        this.timer = setInterval(() => {
            this.props.refreshTweetitsAction(this.props.tweetits_draft.length, hash(this.props.tweetits_draft), ["draft"]).then(()=>{
                this.props.refreshTweetitsAction(this.props.tweetits_suggested.length, hash(this.props.tweetits_suggested), ["suggested"])
            });
            if(this.props.history.location.pathname === "/members"){
                this.props.getUsersRestrictedAction();
            }
        }, 15000);
    }


    componentWillUnmount() {
        clearInterval(this.timer);
    }

    openTweetitDialog = (data) => {
        this.setState({ dialogIsOpen: true, formData: Object.assign(data, this.state.formData)});
    };

    closeTweetitDialog = () => {
        this.setState({ dialogIsOpen: false, formData: {} });
    };

    fastSubmitTweetit(tweet_id, data) {
        this.props.editTweetitAction(tweet_id, data)
    }

    scrollDown(e){
        /*var limit = Math.max( e.scrollHeight, e.offsetHeight,
            e.clientHeight, e.scrollHeight, e.offsetHeight );*/
        //console.log(this.state.scrollEnd, e.scrollTop, limit)
        //this.setState({ scrollEnd: e.scrollTop });
    }

    loadMoreTweetits(e, length, nb, status, loader) {
        if(e.scrollTop>0 && !this.state.loadingMore &&
            ((status[0] === "suggested" && this.state.hasMoreSuggested ) ||
            (status[0] === "draft" && this.state.hasMoreDraft))
        ){
            this.setState({[loader]: true, loadingMore: true, scrollEnd: e.scrollTop}, () => {
                this.props.getTweetitsAction((nb + length), status).then(res => {
                    if(res){
                        let len = 0;
                        let lastName = "";
                        let hasMoreName = "";
                        switch (status[0]){
                            case "draft":
                                len = this.state.lastDraftsTweetitsLength;
                                lastName = "lastDraftsTweetitsLength";
                                hasMoreName = "hasMoreDraft";
                                break;
                            case "suggested":
                                len = this.state.lastSuggestedTweetitsLength;
                                lastName = "lastSuggestedTweetitsLength";
                                hasMoreName = "hasMoreSuggested";
                                break;
                            default:
                                len = 0;
                                break;
                        }
                        if(len === res.filter(t => t.status === status[0]).length){
                            this.setState({[loader]: false, loadingMore:false, [hasMoreName]: false, [lastName]: res.filter(t => t.status === status[0]).length });
                        } else {
                            this.setState({[loader]: false, loadingMore:false, [lastName]: res.filter(t => t.status === status[0]).length})
                        }
                    }else{
                        this.setState({[loader]: false, loadingMore:false})
                    }
                })
                .catch(e => {
                    this.setState({[loader]: false, loadingMore:false})
                });

            })

        }
    }

    render(){
      return(
        <Home
            history={this.props.history}
            formData={this.state.formData}
            user={this.props.user}
            users={this.props.users}
            getMedia={getMedia}
            tweetits={[...this.props.tweetits_draft, ...this.props.tweetits_suggested].sort((a,b) => {return new Date(b.edited_at) - new Date(a.edited_at);})}
            isLoadingDraft={this.props.isLoading_draft}
            isLoadingSuggested={this.props.isLoading_suggested}
            dialogIsOpen={this.state.dialogIsOpen}
            openTweetitDialog={this.openTweetitDialog}
            closeTweetitDialog={this.closeTweetitDialog}
            createTweetitAction={this.props.createTweetitAction}
            editTweetitAction={this.props.editTweetitAction}
            deleteTweetitAction={this.props.deleteTweetitAction}
            fastSubmitTweetit={this.fastSubmitTweetit.bind(this)}
            getTweetAction={this.props.getTweetAction}
            getTweetitsSuggestedAction={this.props.getTweetitsSuggestedAction}
            loadMoreTweetits={this.loadMoreTweetits}
            hasMoreDraft={this.state.hasMoreDraft}
            hasMoreSuggested={this.state.hasMoreSuggested}
            scrollDown={this.scrollDown}
            loadingMoreDraft={(this.state.loadingMoreDraft)}
            loadingMoreSuggested={(this.state.loadingMoreSuggested)}
            loadingMorePublished={(this.state.loadingMorePublished)}
        />
      )
    }
}

const mapStateToProps=(state)=>{
    return state
};
reactMixin(HomeCon.prototype, TimerMixin);
export default connect(mapStateToProps, actionCreators) (HomeCon);