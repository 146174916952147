import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import InfiniteScroll from 'react-infinite-scroll-component';
import 'react-perfect-scrollbar/dist/css/styles.css';

import TweetCard from './misc/tweet-card';
import UserTweetitForm from './misc/user-tweetit-form';
import UserTweetitRefusalForm from './misc/user-tweetit-form-refusal';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

const styles = theme => ({
    root: {
        maxWidth: 1366,
        padding: "79px 0 0 0",
        margin: "0 auto",
        height: "calc(100% - (79px))",
        position: "absolute",
        top: 0,
        width: "100%",
    },
    rootGrid: {
        flexGrow: 1,
        margin: 0,
        top: 0,
        width: "100%",
        overflowX: "hidden",
        overflowY: "scroll",
        overflowScrolling: "touch",
        WebkitOverflowScrolling: "touch",
        height: "100%",
        padding: "0 20px",
        [theme.breakpoints.up('md')]: {
            padding: "0 83px 0 83px",
        },
        "& > div:first-child": {
            maxWidth: "100%",
            width: "100%",
        }
    },
    title: {
        marginBottom: 20,
        fontSize: 15,
        fontWeight: 700,
        color: "rgb(134, 161, 178)",
    },
    scrollbarGrid: {
        paddingTop: 25,
        paddingBottom: 20,
        margin: 0,
        width: "100%",
        overflowX: "hidden",
        overflowY: "scroll",
        overflowScrolling: "touch",
        WebkitOverflowScrolling: "touch",
        [theme.breakpoints.up('md')]: {
            maxHeight: "calc(100vh - (209px))",
        },
    },
    scrollbarContent:{
        margin: 0,
        width: "100%",
        "& > div:first-child": {
            width: "100%",
        }
    },
    scrollbarItem: {
        padding: "10px 0px !important",
        //maxWidth: 350,
        width: "100%",
    },
    emptyGrid: {
        margin: "10px 0px",
        width: "100%",
        [theme.breakpoints.down('md')]: {
            height: "20vh",
        },
        [theme.breakpoints.up('md')]: {
            height: "35vh",
        },
        backgroundColor: "#e1f0f9",
        color: "#697e8b"
    },
    buttonAddTweetit: {
        padding:"12px 0",
        margin: "11px 2px 5px 2px",
        color: "white",
        textDecoration: "none",
        fontSize: 16,
        fontWeight: 700,
        textTransform: 'none',
        borderRadius: 5,
        backgroundColor: "#1ca1f2",
        boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.2)",
    },
    AddCircleIcon: {
        color: "white",
        padding: "0 20px 0 0",
        height: 16,
        width: 16,
    },
    loadingScroll: {
        height: 100,
        paddingTop: "20px !important",
        margin: "0 20px",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
    }
});

class HomeUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lastScrollPos:0,
            direction: null,
            originScrollPos: 0,
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.userScrollbar = React.createRef();
    }

    handleScroll(container) {
        if(this.state.lastScrollPos > container.target.scrollTop) {
            this.setState({
                direction:'top',
                lastScrollPos:container.target.scrollTop,
                originScrollPos: container.target.scrollTop,
            }, then => {
                let posY = this.state.originScrollPos - this.state.lastScrollPos;
                if( posY <= 0)
                    this.props.setHeaderPosition('top', posY)
            });

        } else if(this.state.lastScrollPos < container.target.scrollTop) {
            this.setState({
                direction:'bottom',
                lastScrollPos:container.target.scrollTop,
                originScrollPos: this.state.originScrollPos
            }, then => {
                let posY = this.state.originScrollPos - this.state.lastScrollPos;
                if( posY >= -200)
                    this.props.setHeaderPosition('bottom',posY)
            });
        }
    }

    componentDidMount(){
        this.props.setUserScrollRef(this.userScrollbar)
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root} >
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "center"}}
                    open={this.props.snackbarIsOpen}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.props.snackbarMessage}</span>}
                />
                <UserTweetitRefusalForm
                    formData={this.props.formData}
                    tweetits={this.props.tweetits}
                    user={this.props.user}
                    editTweetitUserAction={this.props.editTweetitUserAction}
                    getTweetAction={this.props.getTweetAction}
                    getTweetitsUserAction={this.props.getTweetitsUserAction}
                    open={this.props.dialogRefusalIsOpen}
                    handleClose={this.props.closeTweetitRefusalDialog}
                    openSnackbar={this.props.openSnackbar}
                />
                <UserTweetitForm
                    formData={this.props.formData}
                    tweetits={this.props.tweetits}
                    user={this.props.user}
                    getMedia={this.props.getMedia}
                    sendTweetAction={this.props.sendTweetAction}
                    createReTweetAction={this.props.createReTweetAction}
                    editTweetitUserAction={this.props.editTweetitUserAction}
                    getTweetAction={this.props.getTweetAction}
                    getTweetitsUserAction={this.props.getTweetitsUserAction}
                    open={this.props.dialogIsOpen}
                    handleClose={this.props.closeTweetitDialog}
                    openSnackbar={this.props.openSnackbar}
                />
                <Grid container ref={this.userScrollbar} id="scrollableDiv" spacing={16} className={classes.rootGrid} >
                    <InfiniteScroll
                        dataLength={this.props.tweetits.filter(t => t.status === "suggested").length}
                        next={() => this.props.loadMoreTweetits(this.props.tweetits.filter(t => t.status === "suggested").length, 2, "suggested")}
                        hasMore={this.props.hasMore}
                        scrollableTarget="scrollableDiv"
                    >
                    <Grid item xs={12} md={6} lg={4} style={{padding: 0}} >
                        <Grid container spacing={16} className={classes.scrollbarGrid} >
                            {
                                this.props.isLoading
                                    ?
                                    <Grid container justify="center" alignItems="center" className={classes.emptyGrid}>
                                        <CircularProgress/>
                                    </Grid>
                                    : (() => {
                                        let items = this.props.tweetits.filter(t => t.status === "suggested");

                                        return items.length === 0
                                            ? <Grid container justify="center" alignItems="center"
                                                    className={classes.emptyGrid}>
                                                Pas encore de contenu
                                            </Grid>
                                            : items.map((tweetit, index, array) => {
                                                let last_tweetit = array[index-1];
                                                let display_title = null;
                                                if(last_tweetit){
                                                    let last_tweetit_title = moment(last_tweetit.created_at).calendar(null,{
                                                        lastDay : '[Hier]',
                                                        sameDay : '[Aujourd\'hui]',
                                                        nextDay : '[Demain]',
                                                        lastWeek : 'dddd [dernier]',
                                                        nextWeek : 'dddd',
                                                        sameElse : 'L'
                                                    })
                                                    let tweetit_title = moment(tweetit.created_at).calendar(null,{
                                                        lastDay : '[Hier]',
                                                        sameDay : '[Aujourd\'hui]',
                                                        nextDay : '[Demain]',
                                                        lastWeek : 'dddd [dernier]',
                                                        nextWeek : 'dddd',
                                                        sameElse : 'L'
                                                    })
                                                    if(last_tweetit_title !== tweetit_title){
                                                        display_title = tweetit_title;
                                                    }
                                                } else {
                                                    display_title = moment(tweetit.created_at).calendar(null,{
                                                        lastDay : '[Hier]',
                                                        sameDay : '[Aujourd\'hui]',
                                                        nextDay : '[Demain]',
                                                        lastWeek : 'dddd [dernier]',
                                                        nextWeek : 'dddd',
                                                        sameElse : 'L'
                                                    })
                                                }
                                                return <Grid xs={12} key={tweetit._id} item className={classes.scrollbarItem}>
                                                    {display_title && (
                                                        <Typography variant="headline" component="h3" className={classes.title}>
                                                            {display_title.charAt(0).toUpperCase() + display_title.slice(1)}
                                                        </Typography>
                                                    )}
                                                    <TweetCard
                                                        showHeader={false}
                                                        id={tweetit._id}
                                                        user={this.props.user}
                                                        getMedia={this.props.getMedia}
                                                        media={"media" in tweetit && tweetit.media ? tweetit.media : null}
                                                        media_alt={"media_alt" in tweetit && tweetit.media_alt ? tweetit.media_alt : "image"}
                                                        message={tweetit.message ? tweetit.message : ""}
                                                        citation={tweetit.citation ? tweetit.citation : {}}
                                                        url={tweetit.url ? tweetit.url : ""}
                                                        created_at={""}
                                                        edited_at={""}
                                                        childs={tweetit.childs ? tweetit.childs : []}
                                                        tweet_ref={tweetit.tweet_ref ? tweetit.tweet_ref : []}
                                                        type={"type" in tweetit? tweetit.type : "tweet"}
                                                        status={tweetit.status}
                                                        curation={"curation" in tweetit ? tweetit.curation : false}
                                                        handleRemove={this.props.openTweetitRefusalDialog}
                                                        handleEdit={this.props.openTweetitDialog}
                                                        handleSubmit={this.props.fastSubmitTweetit}
                                                        submitLabel={null}
                                                        editLabel={null}
                                                        isSubmitable={true}
                                                        isEditable={true}
                                                        isRemovable={true}
                                                        loading={this.props.loading}
                                                    />
                                                </Grid>
                                            })
                                    })()
                            }
                            {this.props.loadingMoreSuggested && (
                                <Grid xs={12} item className={[classes.scrollbarItem, classes.loadingScroll].join(' ')}>
                                    <CircularProgress />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                    </InfiniteScroll>
                </Grid>
            </div>
        );
    }
}

HomeUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeUser);