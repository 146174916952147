import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Switch from '@material-ui/core/Switch';

import moment from 'moment';
import 'moment/locale/fr';
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Button from "@material-ui/core/Button/Button";
import ArrowBackIcon from "../assets/images/arrowDark.png";
import AppBar from "@material-ui/core/AppBar/AppBar";
moment.locale('fr');

const styles = theme => ({
    root: {
        margin: "0 auto",
        width: "100%",
        height: "100vh",
        backgroundColor: "#ffffff",
    },
    rootGrid: {
        flexGrow: 1,
        //position: "relative",
        margin: 0,
        top: 0,
        width: "100%",
        //maxWidth: 1200,
        overflow: "auto",
        padding: "0 20px 0 20px",
        [theme.breakpoints.up('md')]: {
            padding: "0 83px 0 83px",
        },
        "& > div:first-child": {
            maxWidth: "100%",
            width: "100%",
        }
    },
    title: {
        fontSize: 20,
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 700,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "rgb(134, 161, 178)",
    },
    text: {
        fontSize: 15,
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 400,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "rgb(105, 126, 139)",
    },
    appBar: {
        position: 'relative',
        backgroundColor: "#ffffff",
        boxShadow: "none",
    },
    actions:{
        justifyContent: "space-between",
        padding:"25px 25px 30px 25px",
        height: 36,
        minHeight: 36,
    },
    buttonEdit: {
        height: 36,
        width: 36,
        minWidth: 36,
        margin: 0,
        padding: "5px 5px",
        boxShadow: "none",
        borderRadius: 5,
        border: "solid 1px #dbdbdb",
        color: "#697e8b",
        fontWeight: 600,
        backgroundColor: "#ffffff",
        textTransform: "Capitalize",
        '&:active': {
            boxShadow: "none",
        },
    },
    iOSSwitchBase: {
        '&$iOSChecked': {
            color: theme.palette.common.white,
            '& + $iOSBar': {
                backgroundColor: 'rgb(28, 161, 242)',
            },
        },
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
    },
    iOSChecked: {
        transform: 'translateX(15px)',
        '& + $iOSBar': {
            opacity: 1,
            border: 'none',
        },
    },
    iOSBar: {
        borderRadius: 13,
        width: 42,
        height: 26,
        marginTop: -13,
        marginLeft: -21,
        border: 'solid 1px',
        borderColor: theme.palette.grey[400],
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    iOSIcon: {
        width: 24,
        height: 24,
    },
    iOSIconChecked: {
        boxShadow: theme.shadows[1],
    },
});

class SettingsUser extends React.Component {

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root} >
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "center"}}
                    open={this.props.snackbarIsOpen}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.props.snackbarMessage}</span>}
                />
                <AppBar className={classes.appBar}>
                    <Toolbar className={classes.actions}>
                        <Button variant="contained" size="small" className={classes.buttonEdit} onClick={() => this.props.history.replace('/')}>
                            <img alt={"btn_retour"} src={ArrowBackIcon} />
                        </Button>
                    </Toolbar>
                </AppBar>

                <Grid container className={classes.rootGrid} spacing={16} >
                    <Grid item xs={12}>
                        <Typography className={classes.title}>Paramètres</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={classes.rootGrid} spacing={16} >
                            <Grid item xs={7}>
                                <Typography className={classes.text}>Notifications SMS</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Switch
                                    checked={this.props.smsChecked}
                                    onChange={this.props.handleChange('smsChecked')}
                                    value="checkedA"
                                    classes={{
                                        switchBase: classes.iOSSwitchBase,
                                        bar: classes.iOSBar,
                                        icon: classes.iOSIcon,
                                        iconChecked: classes.iOSIconChecked,
                                        checked: classes.iOSChecked,
                                    }}
                                    disableRipple
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

SettingsUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SettingsUser);