import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

/* Redux */
import {createStore, applyMiddleware} from 'redux';
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import reducers from "./reducers/index.js";
import './assets/css/styles.css'

import App from './App.js';

const THEME = createMuiTheme({
    palette: {
        primary: {
            main: "#1ca1f2",
        },
        default: {
            main: "#ffffff",
        },
        secondary: {
            main: "rgb(242, 97, 94)",
        },
    },
    typography: {
        fontSize: 14,
        fontFamily: "'Open Sans'",
        fontWeight: 600,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal"
    },
    overrides: {
        MuiSnackbarContent: {
            root:{
                backgroundColor: "rgb(35, 226, 127)",
                height: 75,
                padding: "27px auto",
                textAlign: "center",
                fontSize: 16,
                fontFamily: "'Open Sans'",
                fontWeight: 600,
            },
            message: {
                width: "100%",
            }
        },
        MuiTouchRipple: {

        },
        MuiListItemText: {
            primary: {
                color: "inherit",
                fontSize: 15,
                fontFamily: "'Open Sans'",
                fontWeight: 600,
                fontStyle: "normal",
                fontStretch: "normal",
                lineHeight: "normal",
                letterSpacing: "normal"
            }
        },
    },
});

let store = createStore(reducers, applyMiddleware(thunk))

ReactDOM.render(
<MuiThemeProvider theme={THEME}>
  <Provider store={store}>
    <App />
  </Provider>
</MuiThemeProvider>
  , document.getElementById('root'));
