import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Slide from "@material-ui/core/Slide/Slide";
import Tab from "@material-ui/core/Tab/Tab";
import Tabs from "@material-ui/core/Tabs/Tabs";
import CloseIcon from '@material-ui/icons/Close';
import ReactSVG from "react-svg";
import SaveIcon from '@material-ui/icons/Save';
import ok from '../../assets/images/ok.svg';
import wrong from '../../assets/images/wrong.svg';
import sendIcon from '../../assets/images/send.svg';
import addImageIcon from '../../assets/images/img.svg';
import addUserIcon from '../../assets/images/add-user.svg';
import defaultUserIcon from '../../assets/images/default_profile_normal.png';
import Avatar from "@material-ui/core/Avatar/Avatar";
import {readAsDataURL} from 'promise-file-reader';
import UsersDialog from "./users-dialog";
import ConfirmDialog from './confirm-dialog';
import xss from 'xss';
import base64 from "base64-arraybuffer";
import mime from 'mime-types';
import path from 'path';
import diff from 'deep-diff';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const styles = theme => ({
    dialogPaper:{
        backgroundColor: "rgba(236, 248, 255, 1)",
        boxShadow: "0 10px 20px 0 rgba(0, 0, 0, 0.2)",
        width: "100%",
        textTransform: 'none',
        [theme.breakpoints.up('sm')]: {
            padding: "40px 60px",
            maxWidth: 800,
            height:575,
        },
        [theme.breakpoints.down('sm')]: {
            padding: "20px 30px",
            margin: "79px 0 0 0",
            height: "calc(100vh - (115px))",
            maxWidth: "100%",
            maxHeight: "100%",
        },
    },
    dialogRoot: {
        '& > div:first-child': {
            backgroundColor: "rgba(255, 255, 255, 0.35)",
        }
    },
    dialogTitle: {
        padding:0,
        position: 'relative'
    },
    dialogContent: {
        overflowX:"hidden",
        padding:0,
    },
    dialogActions: {
        padding:0,
        margin: 0,
        '& span': {
            textTransform: 'none',
        },
    },
    dialogClose: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    tabsRoot: {
        //borderBottom: '1px solid #e8e8e8',
    },
    tabsIndicator: {
        width: 78,
        height: 5,
        borderRadius: 4,
        backgroundColor: "#1ca1f2",
    },
    wrapper: {
        '& span': {
            padding: 0,
        }
    },
    tabRoot: {
        textTransform: 'initial',
        textAlign: "left",
        float: "left",
        fontSize: 15,
        marginRight: theme.spacing.unit * 4,
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 600,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    tabSelected: {
        '& a': {
            color: '#1ca1f2 !important',
        },
    },
    typography: {
        padding: theme.spacing.unit * 3,
    },
    leftIcon: {
        marginRight: 10,
        width: 16,
        height: 16,
    },
    buttonSubmit: {
        margin: 0,
        color: "#ffffff",
        boxShadow: "none",
        float: "right",
        '&:active': {
            boxShadow: "none",
        },
        '&:disabled': {
            color: "#ffffff",
            backgroundColor: "#dbdbdb"
        }
    },
    buttonEdit: {
        margin: "0 20px 0 0",
        boxShadow: "none",
        borderRadius: 5,
        border: "solid 1px #dbdbdb",
        color: "#697e8b",
        fontWeight: 600,
        backgroundColor: "#ffffff",
        '&:active': {
            boxShadow: "none",
        }
    },
    buttonAddImage: {
        paddingLeft: 0,
    },
    buttonAddUser: {
        height:30,
        width:30,
        padding: 0,
        marginRight: 10,
    },
    navLinkCancel: {
        color: "#f2615e",
        textDecoration: "underline",
        position: "absolute",
        [theme.breakpoints.down('xs')]: {
            right: 25,
            bottom: 85,
        },
        [theme.breakpoints.up('sm')]: {
            left: 60,
        },
        [theme.breakpoints.only('sm')]: {
            left: 30,
        },
    },
    textField: {
        backgroundColor: "#ffffff",
        width: "100%",
        margin: "34px 0 11px 0",
        borderRadius: 10,
        "& fieldset": {
            borderRadius: 10,
            border: "solid 1px #c3d0d7",
        },
        "& textarea": {
            //color: "transparent",
            caretColor: theme.palette.text.primary
        }
    },
    itemGrid:{
        padding: "0 8px !important",
    },
    messageWrapper: {
        position: "relative"
    },
    message: {
        pointerEvents: "none",
        position: "absolute",
        zIndex: 1,
        top: 51,
        left: 22,
        right: 22,
        lineHeight: "1.1875em",
        "& span": {
            color: "#1ca1f2"
        }
    },
    avatars: {
        justifyContent: "flex-end",
        display: "flex",
        bottom: 120,
        position: "absolute",
        float: "right",
        paddingLeft: 0,
        paddingRight: 0,
        "& p": {
            color: "#86a1b2",
            fontWeight: 600,
            fontSize: 13,
            lineHeight: "30px",
        },
        '& > div:last-of-type': {
            marginRight: 5,
        }
    },
    avatar: {
        height: 24,
        width: 24,
        marginRight: -13,
        border: "solid 3px #ffffff",
    },
    checkCircle: {
        position: "absolute",
        color: "#23e27f",
        width: 19,
        height: 19,
        [theme.breakpoints.down('md')]: {
            right: 55,
            top: 105,
        },
        [theme.breakpoints.up('md')]: {
            right: 87,
            top: 124,
        },
    },
    input: {
        display: 'none',
    },
    media: {
        width: 150,
        height: 150,
        objectFit: "cover",
        borderRadius: 5,
    },
    mediaAppear: {
        opacity: 0.01,
        transform: "translate(0, 15px)"
    },
    mediaAppearActive: {
        opacity: 1,
        transform: "translate(0, 0)",
        transition: "opacity .15s, transform .15s"
    },
    avatarsEnter: {
        transform: "translate(-15px, 0)",
        opacity: 0
    },
    avatarsEnterActive: {
        transform: "translate(0, 0)",
        opacity: 1,
        transition: "transform .3s cubic-bezier(0.5, -1.5, 0.25, 2.5), opacity 0.3s"
    },
    buttonRemoveMedia: {
        marginLeft: -40,
        marginTop: -250,
        '& rect': {
            fill: "#525252",
        }
    },
    progress: {
        margin: 0,
    },
    progressBtn: {
        margin: "0 10px 0 0",
    },
    tweetCount: {
        position: "absolute",
        right: 72,
        top: 255,
        color: "#23e27f",
        fontWeight: 600,
        fontSize: 12,
    }
});

function MediaTransition(props) {
    return <Slide direction="up" {...props} />;
}

class FormDialog extends React.Component {
    constructor(props) {
        super(props);

        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileRemove = this.handleFileRemove.bind(this);
        this.submitTweetit = this.submitTweetit.bind(this);
        this.setUsers = this.setUsers.bind(this);

        this.state = {
            value: 0,
            message: "",
            formData: {
                id: null,
                users: null,
                message: "",
                media: null,
                media_alt: null,
                url: "",
                tweet_ref: null,
            },
            usersIsOpen: false,
            confirmIsOpen: false,
            urlIsLoading: false,
            urlIsValid: undefined,
            messageIsValid: undefined,
            imageError: null,
            loaded: false,
            old_props: null,
            avatarsIn: false
        };
    }

    /* Vérifie le contenu du message et de url */
    validateContent(message, url) {
        let twitterIdRegex = /status\/([0-9a-zA-Z]*)+$/ig
        let tweet_id = twitterIdRegex.exec(url);

        if( !tweet_id || (tweet_id && tweet_id.length < 2)) {
            this.setState({urlIsValid: false});
        } else if(this.state.value === 1){
            /* Extrait l'id du tweet depuis l'url ensuite demande une vérification à l'api */
            if (tweet_id[1]) {
                this.setState({urlIsLoading: true}, r => {
                    this.props.getTweetAction(tweet_id[1])
                        .then(res => {
                            let formData = Object.assign({}, this.state.formData);
                            formData.message = res.text;
                            formData.tweet_ref = {};
                            formData.tweet_ref.id = res.id_str;
                            formData.tweet_ref.created_at = res.created_at;
                            formData.tweet_ref.lang = res.lang;
                            formData.tweet_ref.retweet_count = res.retweet_count;
                            formData.tweet_ref.favorite_count = res.favorite_count;
                            formData.tweet_ref.user = {};
                            if(res.user){
                                formData.tweet_ref.user.id = res.user.id_str;
                                formData.tweet_ref.user.created_at = res.user.created_at;
                                formData.tweet_ref.user.lang = res.user.lang;
                                formData.tweet_ref.user.name = res.user.name;
                                formData.tweet_ref.user.screen_name = res.user.screen_name;
                                formData.tweet_ref.user.profile_image_url_https = res.user.profile_image_url_https;
                            }

                            /*  Récupère l'image en base 64 */
                            if("media" in res.entities && res.entities.media.length > 0 && res.entities.media[0].type === "photo"){
                                fetch(res.entities.media[0].media_url_https, {
                                    method: 'GET',
                                }).then((response) => {
                                    response.arrayBuffer().then((buffer) => {
                                        var base64Flag = 'data:image/jpeg;base64,';
                                        var binary = '';
                                        var bytes = [].slice.call(new Uint8Array(buffer));
                                        bytes.forEach((b) => binary += String.fromCharCode(b));
                                        var imageStr = window.btoa(binary);
                                        formData.img_src = base64Flag + imageStr;
                                        formData.media = new File([buffer], res.entities.media[0].id_str+path.extname(res.entities.media[0].media_url_https), {type:mime.lookup(res.entities.media[0].media_url_https)});
                                        formData.media_alt = "image";
                                        this.setState({urlIsValid: true, urlIsLoading: false, formData: formData});
                                    });
                                })
                                    .catch((err)=>{
                                        this.setState({urlIsValid: false, urlIsLoading: false, formData: formData});
                                    })
                            }else{
                                formData.media = null;
                                formData.media_alt = null;
                            }

                            this.setState({urlIsValid: true, urlIsLoading: false, formData: formData, message: formData.message.replace(/([^>])?([@#]\S+)/g, '$1<span>$2</span>').replace(/\s/g, '&nbsp;')});
                        })
                        .catch(err => {
                            this.setState({urlIsValid: false, urlIsLoading: false});
                        })
                })
            } else {
                this.setState({urlIsValid: false});
            }
        } else {
            this.setState({urlIsValid: true});
        }

        if (message.length === 0) {
            this.setState({messageIsValid: false});
        }else{
            this.setState({messageIsValid: true});
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.open && diff(nextProps, this.state.old_props)){
            if(nextProps.formData && Object.keys(nextProps.formData).length > 0 && nextProps.formData.constructor === Object) {
                let formData = Object.assign({}, this.state.formData);
                formData = nextProps.formData;

                /* Form validation */
                this.validateContent(nextProps.formData.message, nextProps.formData.url);

                formData.users = formData.childs.map(child => child.user_id);

                if(formData.media){
                    this.getMedia(formData);
                }

                /*  Vérifie si le type pour affiche la bonne fenètre */
                this.setState({message: formData.message.replace(/([^>])?([@#]\S+)/g, '$1<span>$2</span>').replace(/\s/g, '&nbsp;'), old_props: nextProps, formData: formData, value: (formData.type === "retweet") ? 1 : 0});
            }else {
                this.setState({
                    value: 0,
                    message: "",
                    formData: {
                        id: null,
                        users: null,
                        message: "",
                        media: "",
                        media_alt: null,
                        url: "",
                        tweet_ref: null,
                    },
                    urlIsLoading: false,
                    urlIsValid: undefined,
                    messageIsValid: undefined,
                    imageError: null,
                    old_props: nextProps,
                })
            }
        }
    }

    handleChangeTab = (event, value) => {
        this.setState({ value }, res => {
            this.validateContent(this.state.formData.message, this.state.formData.url)
        });
    };

    handleChange = name => event => {
        let formData = Object.assign({}, this.state.formData);
        /* Form validation */
        if (name === "url") {
            let url = event.target.value.replace(/ /g, "")
            this.validateContent(xss(this.state.formData.message), xss(url));
            formData[name] = url;
        } else if (name === "message") {
            this.validateContent(xss(event.target.value), xss(this.state.formData.url));
            formData[name] = event.target.value;
        }


        this.setState({
            message: formData.message.replace(/([^>])?([@#]\S+)/g, '$1<span>$2</span>').replace(/\s/g, '&nbsp;'),
            formData: formData
        });
    };

    getMedia(formData){
        this.props.getMedia(formData.media, formData.id).then(res => {
            res.arrayBuffer().then(r=>{
                this.setState({img_src: "data:image/gif;base64," + base64.encode(r)})
            })
        })
    }

    getBase64(file) {
        return readAsDataURL(file)
            .then(res => {
                return Promise.resolve(res);
            })
            .catch(err => {
                return Promise.reject(err)
            });
    }

    handleFileChange(event) {
        let _this = this;
        const target = event.target;
        if(target.files && target.files.length > 0) {
            let msg_error = null;
            if(target.files[0].type === "image/gif" && target.files[0].size > 15000000){
                msg_error = "Votre gif doit être inférieur à 15mb.";
            }else if(target.files[0].type === "image/jpg" && target.files[0].size > 5000000){
                msg_error = "Votre image doit être inférieure à 5mb.";
            }else if(target.files[0].type === "image/jpeg" && target.files[0].size > 5000000){
                msg_error = "Votre image doit être inférieure à 5mb.";
            }else if(target.files[0].type === "image/png" && target.files[0].size > 5000000){
                msg_error = "Votre image doit être inférieure à 5mb.";
            }else if(target.files[0].type !== "image/png" && target.files[0].type !== "image/jpeg" && target.files[0].type !== "image/jpg" && target.files[0].type !== "image/gif"){
                msg_error = "Format non pris en charge.";
            }


            if (!msg_error) {
                let formData = Object.assign({}, _this.state.formData);
                formData.media = target.files[0];
                let reader = new FileReader();
                reader.onload = (e) => {
                    _this.setState({formData: formData, imageError: null, img_src: e.target.result});
                };
                reader.readAsDataURL(target.files[0]);
            } else {
                this.setState({imageError: msg_error})
            }
        }
    }

    handleFileRemove() {
        let formData = Object.assign({}, this.state.formData);
        formData.media = null;
        this.setState({formData: formData});
    }

    submitTweetit(e, status) {
        let stateLoading = {}
        if(status === "draft"){
            stateLoading = {saveLoading: true}
        }else{
            stateLoading = {submitLoading: true}
        }
        this.setState(stateLoading, () => {
            this.props.createTweetitAction({
                message: this.state.formData.message,
                media: this.state.formData.media ? this.state.formData.media : null,
                media_alt: this.state.formData.media_alt ? this.state.formData.media_alt : null,
                type: (this.state.value === 0) ? "tweet" : "retweet",
                url: this.state.formData.url ? this.state.formData.url : null,
                tweet_ref: this.state.formData.tweet_ref ? this.state.formData.tweet_ref : null,
                status: status,
                users: this.state.formData.users ? this.state.formData.users.map(user => user._id) : [],
            }).then(res => {
                this.setState({
                    value: 0,
                    message: "",
                    formData: {
                        id: null,
                        users: null,
                        message: "",
                        media: "",
                        media_alt: null,
                        url: "",
                        tweet_ref: null,
                    },
                    urlIsLoading: false,
                    urlIsValid: undefined,
                    messageIsValid: undefined,
                    saveLoading: false,
                    submitLoading: false,
                }, res => {
                    this.props.handleClose();
                })
            })
        })

    }

    editTweetit(e, status) {
        let stateLoading = {}
        if(status === "draft"){
            stateLoading = {saveLoading: true}
        }else{
            stateLoading = {submitLoading: true}
        }
        this.setState(stateLoading, () => {
            this.props.editTweetitAction(this.state.formData.id, {
                message: this.state.formData.message,
                media: this.state.formData.media ? this.state.formData.media : null,
                media_alt: this.state.formData.media_alt ? this.state.formData.media_alt : null,
                type: (this.state.value === 0) ? "tweet" : "retweet",
                url: this.state.formData.url ? this.state.formData.url : null,
                tweet_ref: this.state.formData.tweet_ref ? this.state.formData.tweet_ref : null,
                status: status,
                users: this.state.formData.users ? this.state.formData.users.map(user => user._id) : [],
            }).then(res => {
                this.setState({
                    value: 0,
                    message: "",
                    formData: {
                        id: null,
                        users: null,
                        message: "",
                        media: null,
                        media_alt: null,
                        url: "",
                        tweet_ref: null,
                    },
                    urlIsLoading: false,
                    urlIsValid: undefined,
                    messageIsValid: undefined,
                    saveLoading: false,
                    submitLoading: false,
                }, res => {
                    this.props.handleClose();
                })
            })
            .catch(err=>{
                this.setState({
                    value: 0,
                    message: "",
                    formData: {
                        id: null,
                        users: null,
                        message: "",
                        media: null,
                        media_alt: null,
                        url: "",
                        tweet_ref: null,
                    },
                    urlIsLoading: false,
                    urlIsValid: undefined,
                    messageIsValid: undefined,
                    saveLoading: false,
                    submitLoading: false,
                }, res => {
                    this.props.handleClose();
                })
            })
        })
    }

    deleteTweetit(id) {
        this.props.deleteTweetitAction(this.state.formData.id).then(res => {
            this.setState({
                value: 0,
                message: "",
                formData: {
                    id: null,
                    users: null,
                    message: "",
                    media: null,
                    media_alt: null,
                    url: "",
                    tweet_ref: null,
                },
                urlIsLoading: false,
                urlIsValid: undefined,
                messageIsValid: undefined,
            }, res => {
                this.props.handleClose();
                this.handleCancelConfirm();
            })
        })
    }

    openUsersDialog = (e) => {
        this.setState({
            usersAnchorTop: e.currentTarget.getBoundingClientRect().y - 13,
            usersAnchorLeft: e.currentTarget.getBoundingClientRect().x + e.currentTarget.getBoundingClientRect().width / 2,
            usersIsOpen: true,
            avatarsIn: false
        });
    };

    closeUsersDialog = () => {
        this.setState({ usersIsOpen: false, avatarsIn: true });
    };

    setUsers = (users) => {
        if (users.length === 0){
            this.closeUsersDialog()
            return;
        };

        let formData = Object.assign({}, this.state.formData);
        formData.users = users.filter(u => u.selected);
        this.setState({formData: formData}, res => {
            this.closeUsersDialog()
        });
    };

    handleCancelConfirm() {
        this.setState({confirmIsOpen: false});
    }

    handleOpenConfirm() {
        this.setState({confirmIsOpen: true});
    }

    handleValidConfirm() {
        this.deleteTweetit(this.props.formData.id);
    }

    render() {
        const { classes } = this.props;
        const { value } = this.state;
        return (
            <div>
                <ConfirmDialog title={"Confirmation"} message={"Êtes-vous sûr de vouloir supprimer cette suggestion de tweet ? \nVous ne pourrez plus revenir en arrière."} handleCancel={this.handleCancelConfirm.bind(this)} handleValid={this.handleValidConfirm.bind(this)} open={this.state.confirmIsOpen}  />
                <UsersDialog
                    positionTop={this.state.usersAnchorTop}
                    positionLeft={this.state.usersAnchorLeft}
                    users={this.props.users.filter(u => u.status === "enabled")}
                    selectedUsers={this.state.formData.users ? this.state.formData.users : []}
                    open={this.state.usersIsOpen}
                    closeUsersDialog={this.closeUsersDialog}
                    setUsers={this.setUsers}/>
                <Dialog
                    classes={{ paper: classes.dialogPaper, root: classes.dialogRoot }}
                    className={classes.dialog}
                    open={this.props.open}
                    TransitionComponent={MediaTransition}
                    onClose={this.props.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChangeTab}
                            indicatorColor="primary"
                            color="primary"
                            classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                        >
                            <Tab label="Écrire un nouveau Tweet" classes={{ root: classes.tabRoot, wrapper: classes.wrapper, selected: classes.tabSelected }}/>
                            <Tab label="Retweeter un contenu existant" classes={{ root: classes.tabRoot, wrapper: classes.wrapper, selected: classes.tabSelected }}/>
                        </Tabs>
                        <IconButton className={ classes.dialogClose } onClick={this.props.handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        {value === 0 && (
                            <Grid container className={classes.rootGrid} spacing={16} >

                                <Grid item xs={12} className={[classes.itemGrid, classes.messageWrapper].join(' ')}>
                                    {/*<div className={classes.message}
                                         dangerouslySetInnerHTML={{__html: this.state.message}}
                                    />*/}
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label=""
                                        multiline
                                        rows="7"
                                        placeholder="Ecrire un tweet"
                                        rowsMax="7"
                                        value={this.state.formData.message}
                                        onChange={this.handleChange('message')}
                                        className={classes.textField}
                                        margin="normal"
                                        /*inputProps={{ maxLength: 280 }}*/
                                        variant="outlined"
                                    />
                                </Grid>
                                <Typography component={"p"} style={
                                    {color: this.state.formData.message.length < 280 ? "#23e27f" : "#f2615e"}
                                } className={classes.tweetCount}>
                                    {this.state.formData.message.length}
                                </Typography>
                                {this.state.messageIsValid !== undefined && (
                                    <Grid item className={classes.checkCircle}>
                                        {this.state.messageIsValid ? (
                                            <ReactSVG src={ok} />
                                        ) : (
                                            <ReactSVG src={wrong} />
                                        )
                                        }
                                    </Grid>
                                )}
                                <Grid item xs={12} className={classes.itemGrid}>
                                    {(this.state.formData.media && this.state.img_src) ? (
                                        <TransitionGroup>
                                            <CSSTransition
                                                appear={true}
                                                enter={false}
                                                exit={false}
                                                timeout={150}
                                                classNames={{
                                                    appear: classes.mediaAppear,
                                                    appearActive: classes.mediaAppearActive
                                                }} >
                                                <img alt={this.state.formData.media_alt} src={this.state.img_src} className={this.props.classes.media}/>
                                            </CSSTransition>
                                            <CSSTransition
                                                appear={true}
                                                enter={false}
                                                exit={false}
                                                timeout={150}
                                                classNames={{
                                                    appear: classes.mediaAppear,
                                                    appearActive: classes.mediaAppearActive
                                                }} >
                                                <IconButton
                                                    onClick={this.handleFileRemove}
                                                    className={classes.buttonRemoveMedia + " noOver"}
                                                    disableRipple>
                                                    <ReactSVG src={wrong} />
                                                </IconButton>
                                            </CSSTransition>
                                        </TransitionGroup>
                                    ) : (
                                        <div>
                                            <input
                                                accept="image/*"
                                                className={this.props.classes.input}
                                                onChange={this.handleFileChange}
                                                id="media"
                                                name="media"
                                                type="file"
                                            />
                                            <label htmlFor="media">
                                            <IconButton
                                            variant="contained" component="span"
                                            className={classes.buttonAddImage + " noOver"}
                                            disableRipple>
                                                <ReactSVG src={addImageIcon} className={this.props.classes.leftIcon}/>
                                            </IconButton>
                                            </label>
                                            {this.state.imageError && (
                                                <Typography style={{color: "#ff0000"}}>{this.state.imageError}</Typography>
                                            )}
                                        </div>
                                    )}

                                </Grid>
                                <Grid item xs={12} className={classes.itemGrid}>
                                    <Grid container spacing={16} justify="flex-end">
                                        <CSSTransition
                                            in={this.state.avatarsIn}
                                            timeout={300}
                                            classNames={{
                                                enter: classes.avatarsEnter,
                                                enterActive: classes.avatarsEnterActive
                                            }} >
                                            <Grid xs={12} item className={this.props.classes.avatars}>
                                                <IconButton
                                                    className={classes.buttonAddUser + " noOver"}
                                                    disableRipple
                                                    onClick={this.openUsersDialog} >
                                                    <ReactSVG src={addUserIcon} />
                                                </IconButton>
                                                    { this.state.formData.users && this.state.formData.users.map((user, index) => (
                                                        <Avatar alt={"displayName" in user ? user.displayName : user.screen_name} src={"profile_image_url_https" in user ? user.profile_image_url_https : defaultUserIcon} style={{ zIndex: (index - 5) *-1 }} key={index} className={this.props.classes.avatar} />
                                                    ))}
                                                <Typography>
                                                    { (this.state.formData.users && this.state.formData.users.length > 0) ? `${this.state.formData.users.length} personne` + ((this.state.formData.users.length > 1 || this.state.formData.users.length === 0) ? "s" : "") : "À qui soumettre ce tweet ?" }
                                                </Typography>
                                            </Grid>
                                        </CSSTransition>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {value === 1 && (
                            <Grid container className={classes.rootGrid} spacing={16} >

                                <Grid item xs={12} className={classes.itemGrid}>
                                    <TextField
                                        id="outlined-flexible"
                                        label=""
                                        placeholder="Coller le lien du tweet à retweeter"
                                        value={this.state.formData.url}
                                        onChange={this.handleChange('url')}
                                        className={classes.textField}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                                {this.state.urlIsValid !== undefined && (
                                    <Grid item className={classes.checkCircle}>
                                        {(!this.state.urlIsLoading && this.state.urlIsValid) && (
                                            <ReactSVG src={ok} />
                                        )}
                                        {(!this.state.urlIsLoading && !this.state.urlIsValid) && (
                                            <ReactSVG src={wrong} />
                                        )}
                                        {this.state.urlIsLoading && (
                                            <CircularProgress className={classes.progress} size={20}/>
                                        )}
                                    </Grid>
                                )}
                                <Grid item xs={12} className={classes.itemGrid}>
                                    <Grid container spacing={16} justify="flex-end">
                                        <CSSTransition
                                            in={this.state.avatarsIn}
                                            timeout={300}
                                            classNames={{
                                                enter: classes.avatarsEnter,
                                                enterActive: classes.avatarsEnterActive
                                            }} >
                                            <Grid xs={12} item className={this.props.classes.avatars}>
                                                <IconButton
                                                    className={classes.buttonAddUser + " noOver"}
                                                    disableRipple
                                                    onClick={this.openUsersDialog} >
                                                    <ReactSVG src={addUserIcon} />
                                                </IconButton>
                                                { this.state.formData.users && this.state.formData.users.map((user, index) => (
                                                    <Avatar alt={"displayName" in user ? user.displayName : user.screen_name} src={"profile_image_url_https" in user ? user.profile_image_url_https : defaultUserIcon} style={{ zIndex: (index - 5) *-1 }} key={index} className={this.props.classes.avatar} />
                                                ))}
                                                <Typography>
                                                    { (this.state.formData.users && this.state.formData.users.length > 0) ? `${this.state.formData.users.length} personne` + ((this.state.formData.users.length > 1 || this.state.formData.users.length === 0) ? "s" : "") : "À qui soumettre ce tweet ?" }
                                                </Typography>
                                            </Grid>
                                        </CSSTransition>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        {(this.props.formData.id) && (
                            <Button onClick={this.handleOpenConfirm.bind(this)} className={classes.navLinkCancel}>Supprimer</Button>
                        )}
                        {(this.props.formData.status === "draft" || !this.props.formData.id) && (
                            <Button onClick={this.props.formData.id ? (e) => this.editTweetit(e, "draft") : (e) => this.submitTweetit(e, "draft")}variant="contained" className={this.props.classes.buttonEdit} disabled={(!this.state.messageIsValid && this.state.value === 0) || this.state.saveLoading || this.state.submitLoading || ( !this.state.urlIsValid && this.state.value === 1)}>
                                {this.state.saveLoading ?  (
                                    <CircularProgress className={this.props.classes.progressBtn}  size={20} />
                                ) : (
                                    <SaveIcon className={this.props.classes.leftIcon}/>
                                )}
                                Sauvegarder
                            </Button>
                        )}
                        <Button
                            onClick={
                                this.props.formData.id ?
                                    (e) => this.editTweetit(e, "suggested") :
                                    (e) => this.submitTweetit(e, "suggested")
                            }
                            variant="contained" color="primary" className={this.props.classes.buttonSubmit}
                            disabled={
                                (
                                    this.state.submitLoading ||
                                    this.state.saveLoading ||
                                    ((this.state.messageIsValid === undefined || !this.state.messageIsValid || !this.state.formData.users || (this.state.formData.users && this.state.formData.users.length === 0) ) && this.state.value === 0) ||
                                    ((!this.state.urlIsValid || !this.state.formData.users || (this.state.formData.users && this.state.formData.users.length === 0)) && this.state.value === 1 )
                                )
                            }
                        >
                            {this.state.submitLoading ?  (
                                <CircularProgress className={this.props.classes.progressBtn}  size={20} />
                            ) : (
                                <ReactSVG src={sendIcon} className={this.props.classes.leftIcon}/>
                            )}
                            Soumettre
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

FormDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormDialog);