import Constants from "./params";
import {checkToken} from "../actions/utils";

/*
  Récupère un media
 */

export function getMedia(media_id, tweetit_id)
{
    return checkToken().then((t)=> {
        return fetch(`${Constants.DOMAIN}/media?media=` + media_id + '&tweetit=' + tweetit_id, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': t.token
            }

        }).then(response => {
            if (!response.ok) {
                throw (response);
            }
            return response;
        })
            .then(res => {
                return (res)
            })
            .catch((err) => {
                if (err.ok) {
                    return err.json().then(res => {
                        throw(res)
                    });
                } else {
                    throw(err)
                }
            })
    });
}