import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import { fade } from '@material-ui/core/styles/colorManipulator';
import SearchIcon from '@material-ui/icons/Search';
import defaultUserIcon from "../../assets/images/default_profile_normal.png";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 380,
        backgroundColor: theme.palette.background.paper,
        marginBottom: 13,
        position: "absolute",
    },
    content: {
        padding: 10,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        border: "solid 1px #c3d0d7"
    },
    searchIcon: {
        width: 36,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: "#c3d0d7"
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: 7,
        paddingRight: theme.spacing.unit,
        paddingBottom: 9,
        paddingLeft: 36,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
        fontSize: 15,
        color: "#c3d0d7"
    },
    list: {
        padding: "0 !important"
    },
    listSubheader: {
        padding: "0 !important",
        marginTop: 20,
        marginBottom: 5,
        lineHeight: "18px",
        textTransform: "uppercase",
        fontSize: 13,
        fontWeight: 600,
        color: "#86a1b2"
    },
    listItem: {
        padding: "5px 0 !important"
    },
    listItemText: {
        padding: "0 0 0 10px",
        fontSize: 14,
        color: "#697e8b"
    },
    listItemTextSelected: {
        padding: "0 0 0 10px",
        fontSize: 14,
        color: "#1ca1f2"
    },
    checkbox: {
        padding: "0 10px 0 0",
        color: "#c3d0d7"
    },
    avatar: {
        width: 30,
        height: 30
    }
});

class CheckboxListSecondary extends React.Component {
    state = {
        filtered: [],
        scroll: 'paper',
    };

    handleToggle = (index, user) => () => {
        user.selected = !user.selected;
        const { filtered } = this.state;
        const newFiltered = [...filtered];

        this.setState({
            filtered: newFiltered
        });
    };

    handleSearch = (event) => {
        this.setState({
            filtered: this.props.users.filter(u => ("displayName" in u ? (u.displayName.search(new RegExp(event.target.value, 'i')) !== -1) : (u.screen_name.search(new RegExp(event.target.value, 'i')) !== -1)))
        });
    };

    handleClose = () => {
        this.props.setUsers(this.state.filtered);
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.users) {
            nextProps.users.forEach(u => {
                let user = nextProps.selectedUsers.find(f => f._id === u._id);
                u.selected = user ? true : false;
            });
            this.setState({
                filtered: nextProps.users
            });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            this.props.open
                && <div className={classes.root}>
                    <Popover
                        open={this.props.open}
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: this.props.positionTop, left: this.props.positionLeft }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <Paper className={classes.content}>
                            <ClickAwayListener onClickAway={this.handleClose}>
                                <MenuList className={classes.list}>
                                    <div className={classes.search}>
                                        <div className={classes.searchIcon}>
                                            <SearchIcon />
                                        </div>
                                        <InputBase
                                            placeholder="Rechercher"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput,
                                            }}
                                            onChange={this.handleSearch}
                                        />
                                    </div>
                                    <List className={classes.list}>
                                        <ListSubheader className={classes.listSubheader}>Membres</ListSubheader>
                                        {this.state.filtered.map((user, index) => {
                                            return <ListItem key={index} dense button className={classes.listItem}
                                                      onClick={this.handleToggle(index, user)} >
                                                <Checkbox
                                                    checked={user.selected}
                                                    className={classes.checkbox}
                                                    color="primary"
                                                />
                                                <Avatar alt={"displayName" in user ? user.displayName : user.screen_name} src={"profile_image_url_https" in user ? user.profile_image_url_https : defaultUserIcon} className={classes.avatar}/>
                                                <ListItemText
                                                    primary={"displayName" in user ? user.displayName : user.screen_name}
                                                    className={user.selected ? classes.listItemTextSelected : classes.listItemText}
                                                />
                                            </ListItem>
                                        })}
                                        {this.state.filtered.length === 0 && (
                                            <ListItem dense className={classes.listItem} >
                                               <ListItemText
                                                    primary="Aucun membre"
                                                    className={classes.listItemText}
                                                />
                                            </ListItem>
                                        )}
                                    </List>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popover>
            </div>
        );
    }
}

CheckboxListSecondary.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CheckboxListSecondary);