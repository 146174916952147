import Constants from '../utils/params';
import hash from 'object-hash';
import {handleErrors, catchErrors, checkToken} from './utils';

/*
  Création d'un tweetit
  data = {message(string), media_alt(string), media(base64), users(array)}
 */
export function createTweetitAction(data)
{
    return(dispatch)=>{
        return checkToken().then((t)=> {
            /* Supprime les champs vides */
            Object.keys(data).forEach(key => data[key] === null && delete data[key]);

            /* Converti le champ user en json */
            if (data.users)
                data.users = JSON.stringify(data.users)
            if (data.tweet_ref)
                data.tweet_ref = JSON.stringify(data.tweet_ref)
            var formData = new FormData();
            for (var key in data) {
                formData.append(key, data[key]);
            }

            return fetch(`${Constants.DOMAIN}/tweetit`, {
                method: 'post',
                headers: {
                    'x-auth-token': t.token,
                    'key': t.key
                },
                body: formData
            }).then(handleErrors)
                .then(res => {
                    dispatch({
                        type: "ADD_TWEETIT",
                        tweetit: res,
                    });
                    return (res)
                })
                .catch(catchErrors)
        });
  }
}

/*
  Edition d'un tweetit
  data = {message(string), media_alt(string), media(base64)}
 */
export function editTweetitAction(tweetit_id, data)
{
  return(dispatch)=>{
      return checkToken().then((t)=> {
          /* Supprime les champs vides */
          Object.keys(data).forEach(key => (data[key] === null && key !== "media") && delete data[key]);

          /* Converti le champ user en json */
          if (data.users)
              data.users = JSON.stringify(data.users)
          if (data.tweet_ref)
              data.tweet_ref = JSON.stringify(data.tweet_ref)
          /* Si la champ media ne contient pas d'objet file alors on le supprime */
          if (typeof data.media !== "object" && data.media != null)
              delete data.media

          var formData = new FormData();
          for (var key in data) {
              formData.append(key, data[key]);
          }
          formData.append("tweet", tweetit_id);
          return fetch(`${Constants.DOMAIN}/tweetit`, {
              method: 'put',
              headers: {
                  'x-auth-token': t.token,
                  'key': t.key
              },
              body: formData
          }).then(handleErrors)
              .then(res => {
                  dispatch({
                      type: "REPLACE_TWEETIT",
                      old_tweetit_id: tweetit_id,
                      new_tweetit: res,
                  });
                  return (res)
              })
              .catch(catchErrors)
      });
  }
}

/*
  Suppression d'un tweetit/retweetit
 */
export function deleteTweetitAction(tweetit_id)
{
  return(dispatch)=>{
      return checkToken().then((t)=> {
          return fetch(`${Constants.DOMAIN}/tweetit`, {
              method: 'delete',
              headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': t.token
              },
              body: JSON.stringify({tweet: tweetit_id})
          }).then(handleErrors)
              .then(res => {
                  dispatch({
                      type: "REMOVE_TWEETIT",
                      tweetit_id: tweetit_id,
                  });
                  return (res)
              })
              .catch(catchErrors)
      });
  }
}

/*
  Récupère la liste des tweetits par status
 */
export function getTweetitsAction(limit, status)
{
  return(dispatch)=>{
      return checkToken().then((t)=> {
          return fetch(`${Constants.DOMAIN}/tweetits?limit=` + (limit ? limit : 0) + (status ? ('&status=' + status.join()) : ""), {
              method: 'get',
              headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': t.token
              }

          }).then(handleErrors)
              .then(res => {
                  switch (status[0]) {
                      case "draft":
                          dispatch({
                              type: "UPDATE_DRAFT_TWEETITS",
                              tweetits: res
                          });
                          break;
                      case "suggested":
                          dispatch({
                              type: "UPDATE_SUGGESTED_TWEETITS",
                              tweetits: res
                          });
                          break;
                      default:
                          break;
                  }

                  return (res)
              })
              .catch(catchErrors)
      });
  }
}


/*
  Met à jour la liste des tweetits si hash différent
 */
export function refreshTweetitsAction(limit, last_hash, status)
{
    return(dispatch)=>{
        return checkToken().then((t)=> {

            return fetch(`${Constants.DOMAIN}/tweetits?limit=` + (limit ? limit : 0) + (status ? ('&status=' + status.join()) : ""), {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': t.token
                }

            }).then(handleErrors)
                .then(res => {
                    if (last_hash !== hash(res)) {
                        switch (status[0]) {
                            case "draft":
                                dispatch({
                                    type: "UPDATE_DRAFT_TWEETITS",
                                    tweetits: res
                                });
                                break;
                            case "suggested":
                                dispatch({
                                    type: "UPDATE_SUGGESTED_TWEETITS",
                                    tweetits: res
                                });
                                break;
                            default:
                                break;
                        }
                    }
                })
                .catch(catchErrors)
        });
    }
}

/*
  Récupère la liste des tweetits sugéré (reçu par des utilisateurs, exclut le tweetit original du résultat)
 */
export function getTweetitsSuggestedAction(tweet_id)
{
    return(dispatch)=>{
        return checkToken().then((t)=> {
            return fetch(`${Constants.DOMAIN}/tweetits/suggested?tweet=` + tweet_id, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': t.token
                }

            }).then(handleErrors)
                .then(res => {
                    dispatch({
                        type: "UPDATE_TWEETITS",
                        tweetits: res
                    });
                    return (res)
                })
                .catch(catchErrors)
        });
    }
}

/*
  Récupère la liste des utilisateurs
 */
export function getUsersRestrictedAction()
{
    return(dispatch)=>{
        return checkToken().then((t)=> {
            return fetch(`${Constants.DOMAIN}/users/restricted`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': t.token
                }
            }).then(handleErrors)
                .then(res => {
                    dispatch({
                        type: "UPDATE_USERS",
                        users: res
                    });
                    return (res)
                })
                .catch(catchErrors)
        });
    }
}

/*
  Récupère un tweet par id
 */
export function getTweetAction(tweet_id)
{
    return(dispatch)=>{
        return checkToken().then((t)=> {
            return fetch(`${Constants.DOMAIN}/tweet/?tweet=` + tweet_id, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': t.token,
                    'key': t.key
                }

            }).then(handleErrors)
                .then(res => {
                    return (res)
                })
                .catch(catchErrors)
        });
    }
}

/*
  Renvoi l'invitation
 */
export function sendInvitationAction(user_id)
{
    return(dispatch)=>{
        return checkToken().then((t)=> {
            return fetch(`${Constants.DOMAIN}/user/invitation?user=` + user_id, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': t.token
                }

            }).then(handleErrors)
                .then(res => {
                    return (res)
                })
                .catch(catchErrors)
        });
    }
}