import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import flatten from 'array-flatten';

import TweetCard from './misc/tweet-card';
import TweetitDialog from './misc/tweetit-form';

import MembersCon from "../containers/members-container.js";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import Button from "@material-ui/core/Button/Button";

const styles = theme => ({
    root: {
        maxWidth: 1366,
        margin: "0 auto",
        width: "100%",
    },
    rootGrid: {
        flexGrow: 1,
        //position: "relative",
        margin: 0,
        top: 0,
        marginTop: 30,
        width: "100%",
        //maxWidth: 1200,
        height: "100%",
        padding: "0 10px 0 20px",
        [theme.breakpoints.up('md')]: {
            padding: "0 59px",
        },
    },
    subGrid: {
        padding: "10px 0 20px 0 !important"
    },
    title: {
        padding: "0 20px",
        fontSize: 20,
        fontWeight: 700,
        color: "#525252",
    },
    scrollbarGrid: {
        marginTop: 10,
        margin: 0,
        display: "block",
        width: "100%",
        overflowY: "auto",
        [theme.breakpoints.up('md')]: {
            maxHeight: "calc(100vh - (209px))",
        }
    },
    scrollbarContent:{
        margin: 0,
        width: "100%",
        /*maxWidth: 350,*/
        "& > div:first-child": {
            width: "100%",
        }
    },
    scrollbarItem: {
        padding: "10px 0px !important",
        margin: "0 20px",
        minWidth: 350,
        width: "calc(100% - 40px)"
    },
    emptyGrid: {
        margin: "10px 20px",
        minWidth: 350,
        width: "calc(100% - 40px)",
        [theme.breakpoints.down('md')]: {
            height: "20vh",
        },
        [theme.breakpoints.up('md')]: {
            height: "35vh",
        },
        backgroundColor: "#e1f0f9",
        color: "#697e8b"
    },
    buttonAddTweetit: {
        padding:"12px 0",
        margin: "11px 20px 10px 20px",
        width: "calc(100% - 40px)",
        minWidth: 350,
        height: 60,
        color: "white",
        textDecoration: "none",
        fontSize: 16,
        fontWeight: 700,
        textTransform: 'none',
        borderRadius: 5,
        backgroundColor: "#1ca1f2",
        boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.2)",
        WebkitTextSizeAdjust: "none",
        WebkitFontSmoothing: "antialiased",
        MozOsxFontSmoothing: "grayscale"
    },
    AddCircleIcon: {
        color: "white",
        padding: "0 20px 0 0",
        height: 16,
        width: 16,
    },
    loadingScroll: {
        height: 100,
        paddingTop: "20px !important",
        margin: "0 20px",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
    },
    tabsRoot: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 2,
        width: 350,
        margin: "0 auto",
        height: 79,
        fontSize: 15,
        backgroundColor: "#ffffff",
    },
    tabsIndicator: {
        width: 78,
        height: 5,
        borderRadius: 4,
        backgroundColor: "#1ca1f2",
    },
    wrapper: {
        height: 79,
        width: 80,
    },
    tabsLabel:{
        color: '#525252',
        fontSize: 15,
    },
    flexContainer:{
        height: "100%",
        width: 350,
        margin: "0 auto",
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 80,
        width: 90,
        fontSize: 15,
        marginRight: theme.spacing.unit * 4,
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 600,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    tabSelected: {
        '& p': {
            color: '#1ca1f2 !important',
        },
    },
    container: {
        overflowY: "auto",
        height: "calc(100vh - 79px)",
    },
});

function TabContainer({ children, dir, classes }) {
    return (
        <PerfectScrollbar
            className={classes.container}
            option={{
                wheelSpeed: 1,
                wheelPropagation: true,
                swipeEasing:false,
                minScrollbarLength: 5
            }}
        >
            <Typography component="div" dir={dir} >
                {children}
            </Typography>
        </PerfectScrollbar>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
};

class HomeManager extends React.Component {

    state = {
        tabValue: 0,
    };

    componentWillMount(){
        switch(this.props.history.location.pathname){
            case "/":
                this.setState({ tabValue: 0 });
                break;
            case "/members":
                this.setState({ tabValue: 1 });
                break;
            case "/members/create":
                this.setState({ tabValue: 1 });
                break;
            case "/members/edit":
                this.setState({ tabValue: 1 });
                break;
            default:
                break;
        }
    }

    handleChangeTabs = (event, tabValue) => {
        switch(tabValue){
            case 0:
                this.props.history.replace('/')
                break;
            case 1:
                this.props.history.replace('/members')
                break;
            default:
                break;
        }
        this.setState({ tabValue });
    };

    handleChangeTabsIndex = index => {
        this.setState({ tabValue: index });
    };


    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <TweetitDialog
                    formData={this.props.formData}
                    user={this.props.user}
                    getMedia={this.props.getMedia}
                    users={this.props.users.filter(user => user.role === "User")}
                    createTweetitAction={this.props.createTweetitAction}
                    createReTweetitAction={this.props.createReTweetitAction}
                    editTweetitAction={this.props.editTweetitAction}
                    deleteTweetitAction={this.props.deleteTweetitAction}
                    getTweetAction={this.props.getTweetAction}
                    getTweetitsSuggestedAction={this.props.getTweetitsSuggestedAction}
                    open={this.props.dialogIsOpen}
                    handleClose={this.props.closeTweetitDialog}
                />
                <Tabs
                    value={this.state.tabValue}
                    onChange={this.handleChangeTabs.bind(this)}
                    indicatorColor="primary"
                    textColor="primary"
                    classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator, flexContainer: classes.flexContainer}}
                    fullWidth
                >
                    <Tab label={<Typography >Dashboard</Typography>} classes={{ root: classes.tabRoot, wrapper: classes.wrapper, selected: classes.tabSelected }}/>
                    <Tab label={<Typography >Membres</Typography>} classes={{ root: classes.tabRoot, wrapper: classes.wrapper, selected: classes.tabSelected }}/>
                </Tabs>
                <SwipeableViews
                    axis={'x'}
                    index={this.state.tabValue}
                    onChangeIndex={this.handleChangeTabsIndex.bind(this)}

                >
                    <TabContainer dir="ltr" classes={classes}>
                        <Grid container className={classes.rootGrid} justify="space-between">

                            <Grid item xs={12} md={6} lg={4} className={classes.subGrid}>

                                <Typography variant="headline" component="h3" className={classes.title}>
                                    Brouillons
                                </Typography>
                                <PerfectScrollbar
                                    id="scrollableDraftDiv"
                                    className={classes.scrollbarGrid}
                                    onYReachEnd={(e) => this.props.loadMoreTweetits(e, this.props.tweetits.filter(t => t.status === "draft").length, 2, ["draft"], "loadingMoreDraft")}
                                    option={{
                                        wheelSpeed: 1,
                                        wheelPropagation: true,
                                        swipeEasing:false,
                                        minScrollbarLength: 5
                                    }}
                                >
                                    <Grid container spacing={16} className={classes.scrollbarContent}>
                                        <Button fullWidth variant="contained" color="primary" onClick={() => this.props.openTweetitDialog({})} className={classes.buttonAddTweetit} disableRipple={true}>
                                            <AddCircleIcon className={classes.AddCircleIcon}/>
                                            Ajouter un tweet
                                        </Button>
                                        {
                                            this.props.isLoadingDraft
                                                ? <Grid container justify="center" alignItems="center" className={classes.emptyGrid}>
                                                    <CircularProgress />
                                                </Grid>
                                                : (() => {
                                                    let items = this.props.tweetits
                                                        .filter(t => t.status === "draft")
                                                        .sort((a, b) => new Date(b.edited_at) - new Date(a.edited_at));

                                                    return items.length === 0
                                                        ? <Grid container justify="center" alignItems="center" className={classes.emptyGrid}>
                                                            Pas encore de brouillon
                                                        </Grid>
                                                        : items.map(tweetit => (
                                                            <Grid xs={12} key={tweetit._id} item className={classes.scrollbarItem}>
                                                                <TweetCard
                                                                    showHeader={true}
                                                                    id={tweetit._id}
                                                                    user={this.props.user}
                                                                    users={this.props.users}
                                                                    user_id={tweetit.user_id}
                                                                    getMedia={this.props.getMedia}
                                                                    media={"media" in tweetit && tweetit.media ? tweetit.media : null}
                                                                    media_alt={"media_alt" in tweetit && tweetit.media_alt ? tweetit.media_alt : "image"}
                                                                    message={tweetit.message ? tweetit.message : ""}
                                                                    citation={tweetit.citation ? tweetit.citation : {}}
                                                                    url={tweetit.url ? tweetit.url : ""}
                                                                    created_at={tweetit.created_at}
                                                                    edited_at={tweetit.edited_at}
                                                                    childs={tweetit.childs ? tweetit.childs : []}
                                                                    tweet_ref={tweetit.tweet_ref ? tweetit.tweet_ref : []}
                                                                    type={"type" in tweetit? tweetit.type : "tweet"}
                                                                    status={tweetit.status}
                                                                    curation={"curation" in tweetit ? tweetit.curation : false}
                                                                    handleEdit={this.props.openTweetitDialog}
                                                                    handleSubmit={this.props.fastSubmitTweetit}
                                                                    isSubmitable={tweetit.childs ? tweetit.childs.length > 0 && tweetit.message.length <= 280 : false}
                                                                    submitLabel={"Soumettre"}
                                                                    editLabel={"Editer"}
                                                                    isEditable={true}
                                                                    isRemovable={false}
                                                                />
                                                            </Grid>
                                                        ))
                                                })()
                                        }
                                        {this.props.loadingMoreDraft && (
                                            <Grid xs={12} item className={[classes.scrollbarItem, classes.loadingScroll].join(' ')}>
                                                <CircularProgress />
                                            </Grid>
                                        )}
                                    </Grid>
                                </PerfectScrollbar>
                            </Grid>

                            <Grid item xs={12} md={6} lg={4} className={classes.subGrid}>
                                <Typography variant="headline" component="h3" className={classes.title}>
                                    Soumis
                                </Typography>
                                <PerfectScrollbar
                                    id="scrollableSuggestedDiv"
                                    className={classes.scrollbarGrid}
                                    onScrollDown={this.props.scrollDown}
                                    onYReachEnd={(e) => this.props.loadMoreTweetits(e, this.props.tweetits.filter(t => t.status === "suggested").length, 2, ["suggested"], "loadingMoreSuggested")}
                                    option={{
                                        wheelSpeed: 1,
                                        wheelPropagation: true,
                                        swipeEasing:false,
                                        minScrollbarLength: 5
                                    }}
                                >
                                    <Grid container spacing={16} className={classes.scrollbarContent}>
                                        {
                                            this.props.isLoadingSuggested
                                                ? <Grid container justify="center" alignItems="center" className={classes.emptyGrid}>
                                                    <CircularProgress />
                                                </Grid>
                                                : (() => {
                                                    let items = this.props.tweetits
                                                    /* Vérifie si le tweetit a un status suggéré et si les utilisateurs l'ayant reçu ont tous donné une réponse */
                                                        .filter(t =>  t.status === "suggested" && ((!t.childs)||(t.childs && t.childs.filter(t2 => t2.status === "suggested").length > 0)))
                                                        .sort((a, b) =>  new Date(b.edited_at) - new Date(a.edited_at));

                                                    return items.length === 0
                                                        ? <Grid container justify="center" alignItems="center"
                                                                className={classes.emptyGrid}>
                                                            Pas encore de soumission
                                                        </Grid>
                                                        : items.map(tweetit => (
                                                            <Grid xs={12} key={tweetit._id} item className={classes.scrollbarItem}>
                                                                <TweetCard
                                                                    showHeader={true}
                                                                    id={tweetit._id}
                                                                    user={this.props.user}
                                                                    users={this.props.users}
                                                                    user_id={tweetit.user_id}
                                                                    getMedia={this.props.getMedia}
                                                                    media={"media" in tweetit && tweetit.media ? tweetit.media : null}
                                                                    media_alt={"media_alt" in tweetit && tweetit.media_alt ? tweetit.media_alt : "image"}
                                                                    message={tweetit.message ? tweetit.message : ""}
                                                                    citation={tweetit.citation ? tweetit.citation : {}}
                                                                    url={tweetit.url ? tweetit.url : ""}
                                                                    created_at={tweetit.created_at}
                                                                    edited_at={tweetit.edited_at}
                                                                    childs={tweetit.childs ? tweetit.childs.filter(c => c.status === "suggested") : []}
                                                                    tweet_ref={tweetit.tweet_ref ? tweetit.tweet_ref : []}
                                                                    type={"type" in tweetit? tweetit.type : "tweet"}
                                                                    status={tweetit.status}
                                                                    curation={"curation" in tweetit ? tweetit.curation : false}
                                                                    handleEdit={this.props.openTweetitDialog}
                                                                    isSubmitable={false}
                                                                    submitLabel={"Soumettre"}
                                                                    editLabel={"Editer"}
                                                                    isEditable={false}
                                                                    isRemovable={false}
                                                                />
                                                            </Grid>
                                                        ))
                                                })()
                                        }
                                        {this.props.loadingMoreSuggested && (
                                            <Grid xs={12} item className={[classes.scrollbarItem, classes.loadingScroll].join(' ')}>
                                                <CircularProgress />
                                            </Grid>
                                        )}
                                    </Grid>
                                </PerfectScrollbar>
                            </Grid>

                            <Grid item xs={12} md={6} lg={4} className={classes.subGrid}>
                                <Typography variant="headline" component="h3" className={classes.title}>
                                    Traités
                                </Typography>
                                <PerfectScrollbar
                                    id="scrollablePublishedDiv"
                                    onYReachEnd={(e) => this.props.loadMoreTweetits(e, this.props.tweetits.filter(t => t.status === "suggested").length, 2, ["suggested"], "loadingMorePublished")}
                                    className={classes.scrollbarGrid}
                                    option={{
                                        wheelSpeed: 1,
                                        wheelPropagation: true,
                                        swipeEasing:false,
                                        minScrollbarLength: 5
                                    }}
                                >
                                    <Grid container spacing={16} className={classes.scrollbarContent}>
                                        {
                                            this.props.isLoadingSuggested
                                                ? <Grid container justify="center" alignItems="center" className={classes.emptyGrid}>
                                                    <CircularProgress />
                                                </Grid>
                                                : (() => {
                                                    let items = flatten(this.props.tweetits.map(tw => tw.childs))
                                                        .filter(t => t && (t.status === "published" || t.status === "edited" || t.status === "removed"))
                                                        .sort((a, b) => new Date(b.edited_at) - new Date(a.edited_at));

                                                    return items.length === 0
                                                        ? <Grid container justify="center" alignItems="center"
                                                                className={classes.emptyGrid}>
                                                            Pas encore de posts traités
                                                        </Grid>
                                                        : items.map(tweetit => (
                                                            <Grid xs={12} key={tweetit._id} item className={classes.scrollbarItem}>
                                                                <TweetCard
                                                                    showHeader={true}
                                                                    id={tweetit._id}
                                                                    user={this.props.user}
                                                                    getMedia={this.props.getMedia}
                                                                    media={"media" in tweetit && tweetit.media ? tweetit.media : null}
                                                                    media_alt={"media_alt" in tweetit && tweetit.media_alt ? tweetit.media_alt : "image"}
                                                                    message={tweetit.message ? tweetit.message : ""}
                                                                    citation={tweetit.citation ? tweetit.citation : {}}
                                                                    refusal_message={tweetit.refusal_message ? tweetit.refusal_message : ""}
                                                                    url={tweetit.url ? tweetit.url : ""}
                                                                    created_at={tweetit.created_at}
                                                                    edited_at={tweetit.edited_at}
                                                                    childs={[tweetit]}
                                                                    tweet_ref={tweetit.tweet_ref ? tweetit.tweet_ref : []}
                                                                    type={"type" in tweetit? tweetit.type : "tweet"}
                                                                    status={tweetit.status}
                                                                    curation={"curation" in tweetit ? tweetit.curation : false}
                                                                    handleEdit={this.props.openTweetitDialog}
                                                                    isSubmitable={false}
                                                                    submitLabel={"Soumettre"}
                                                                    editLabel={"Editer"}
                                                                    isEditable={false}
                                                                    isRemovable={false}
                                                                />
                                                            </Grid>
                                                        ))
                                                })()
                                        }
                                        {this.props.loadingMorePublished && (
                                            <Grid xs={12} item className={[classes.scrollbarItem, classes.loadingScroll].join(' ')}>
                                                <CircularProgress />
                                            </Grid>
                                        )}
                                    </Grid>
                                </PerfectScrollbar>
                            </Grid>

                        </Grid>
                    </TabContainer>
                    <TabContainer dir="ltr" classes={classes}>
                        <MembersCon
                            tabValue={this.state.tabValue}
                            history={this.props.history}
                            formData={this.state.formData}
                            user={this.props.user}
                            users={this.props.users}
                        />
                    </TabContainer >
                </SwipeableViews>

            </div>
        );
    }
}

HomeManager.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeManager);