import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import clearIcon from "../../assets/images/clear-icon.svg";
import twitterIcon from '../../assets/images/twitter.svg';
import scheduleIcon from '../../assets/images/schedule.svg';
import Avatar from '@material-ui/core/Avatar';

import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ReactSVG from "react-svg";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Menu from "@material-ui/core/Menu/Menu";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
    { id: 'Nom', numeric: false, disablePadding: true, label: 'Nom' },
    { id: 'Comptes liés', numeric: false, disablePadding: true, label: 'Comptes liés' },
    { id: 'Type de compte', numeric: false, disablePadding: true, label: "Rôle de l'utilisateur" },
    { id: 'Etat', numeric: false, disablePadding: true, label: 'Etat' },
    { id: 'Actions', numeric: false, disablePadding: true, label: 'Actions' },
];

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { order, orderBy } = this.props;

        return (
            <TableHead>
                <TableRow>
                    {/*<TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>*/}
                    {rows.map(row => {
                        return (
                            <TableCell
                                key={row.id}
                                numeric={row.numeric}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
    root: {
        padding: "30px 40px",
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
    buttonAdd: {
        color: "#ffffff",
        textTransform: "initial",
        backgroundColor: "#23e27f",
        "&:hover": {
            backgroundColor: "#1cb365",
        },
    },
    AddCircleIcon: {
        width: 16,
        height: 16,
        marginRight: 10,
    },
});

let EnhancedTableToolbar = props => {
    const { numSelected, classes, handleChangePage } = props;

    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                <Button variant="contained" disableRipple color="primary" className={classes.buttonAdd} onClick={() => handleChangePage(1, null, true)}>
                    <AddCircleIcon className={classes.AddCircleIcon} />
                    Ajouter un utilisateur
                </Button>
            </div>
            <div className={classes.spacer} />
            {/*<div className={classes.actions}>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Filter list">
                        <IconButton aria-label="Filter list">
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </div>*/}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflow: 'auto',
        margin: "0 40px",
    },
    toolBar: {
        margin: "0 40px",
    },
    name: {
        "& div": {
            float: "left",
            margin: "20px 0",
            width: 35,
            height: 35,
        },
        "& p": {
            margin: "30px 0 0 20px",
            float: "left",
        },
    },
    moreButton: {
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
        },
        '&:focus': {
            boxShadow: 'none',
        },
    },
    menuItem: {
        textAlign: "center",
        paddingLeft: "0 !important",
        paddingRight: "0 !important",
        fontSize: "15px !important",
        color: "#697e8b"
    },
    dialogContentText: {
        padding: "50px 100px",
        textAlign: "center",
        fontSize: 15,
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 600,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#525252",
    },
    dialogActions: {
        display: "flex",
        justifyContent: "center",
        margin: "0 0 50px 0",
    },
    dialogBtnRemove:{
        boxShadow: "none",
        margin: "0 0 0 20px",
        "&:active": {
            boxShadow: "none",
        },
    },
});

class EnhancedTable extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'calories',
        selected: [],
        page: 0,
        rowsPerPage: 30,
        anchorEl: null,
        anchorElUser: null,
        openValiationModal: false,
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleSelectAllClick = event => {
        /*if (event.target.checked) {
            this.setState(state => ({ selected: state.data.map(n => n.id) }));
            return;
        }
        this.setState({ selected: [] });*/
    };

    handleClick = (event, id) => {
        /*const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });*/
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    handleMenuOpen = (event, user) => {
        this.setState({ anchorEl: event.currentTarget, anchorElUser: user });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    disableUser(){
        this.props.editAccountAction(this.state.anchorElUser.id, null, null, this.state.anchorElUser.status === "enabled" ? "disabled" : "enabled");
        this.handleMenuClose()
    }

    openDialogRemoveUser(user){
        if(user) {
            this.setState({openValiationModal: true});
            this.handleMenuClose()
        }
    }

    removeUser(){
        if(this.state.anchorElUser && this.state.anchorElUser.id){
            this.props.deleteUser(this.state.anchorElUser.id);
            this.setState({ openValiationModal: false });
        }
    }

    handleCloseRemoveDialog = () => {
        this.setState({anchorElUser: null, openValiationModal: false });
    };

    render() {
        const isMenuOpen = Boolean(this.state.anchorEl);
        const { classes } = this.props;
        let users = [...this.props.users, ...[this.props.user]];
        const { order, orderBy, selected, rowsPerPage, page } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

        return (
            <Paper className={classes.root}>
                <Dialog
                    open={this.state.openValiationModal}
                    onClose={this.handleCloseRemoveDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" className={classes.dialogContentText}>
                            Êtes-vous sûr de vouloir supprimer ce membre ?
                            Vous ne pourrez plus revenir en arrière.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button variant="outlined" onClick={this.handleCloseRemoveDialog}>
                            Annuler
                        </Button>
                        <Button variant="contained" className={classes.dialogBtnRemove} onClick={this.removeUser.bind(this)} color="secondary" autoFocus>
                            Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>
                <EnhancedTableToolbar numSelected={selected.length} handleChangePage={this.props.handleChangePage} />
                <div className={classes.tableWrapper}>
                    <Menu
                        className="popover_class"
                        PaperProps={{
                            style: {
                                marginTop: -10,
                                width: 200,
                                textAlign: "center",
                                borderRadius: 5,
                                backgroundColor: "transparent",
                                boxShadow: "none",
                                overflow: "visible",
                            },
                        }}
                        anchorEl={this.state.anchorEl}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        getContentAnchorEl={null}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={isMenuOpen}
                        onClose={this.handleMenuClose}
                    >
                        {this.state.anchorElUser && this.state.anchorElUser.status === "enabled" && <MenuItem onClick={() => this.props.handleChangePage(2, this.state.anchorElUser, true)}><ListItemText className={classes.menuItem} inset primary="Editer" /></MenuItem>}
                        {this.state.anchorElUser && this.props.user.id !== this.state.anchorElUser.id && <MenuItem onClick={this.disableUser.bind(this)}><ListItemText style={{color:(this.state.anchorElUser && this.state.anchorElUser.status === "enabled" ? "#f2615e" : "#23e27f")}} color="secondary" className={classes.menuItem} inset primary={this.state.anchorElUser && this.state.anchorElUser.status === "enabled" ? "Désactiver" : "Activer"} /></MenuItem>}
                        {this.state.anchorElUser && this.props.user.id !== this.state.anchorElUser.id && this.state.anchorElUser.status === "enabled" && <MenuItem onClick={() => this.openDialogRemoveUser(this.state.anchorElUser)}><ListItemText style={{color:"#f2615e"}} className={classes.menuItem} inset primary="Supprimer" /></MenuItem>}
                    </Menu>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={users.length}
                        />
                        <TableBody>
                            {stableSort(users, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((n, index) => {
                                    const isSelected = this.isSelected(n.id);
                                    return (
                                        <TableRow
                                            onClick={event => this.handleClick(event, n.id)}
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.id}
                                            selected={isSelected}
                                        >
                                            {/*<TableCell padding="checkbox">
                                                <Checkbox checked={isSelected} />
                                            </TableCell>*/}
                                            <TableCell component="th" scope="row" padding="none" className={classes.name} >
                                                {n.avatar ? (
                                                    <Avatar src={n.avatar} className={classes.avatar} />
                                                ) : (
                                                    <Avatar className={classes.avatar}>{n.screen_name ? n.screen_name.charAt(0) : ""}</Avatar>
                                                )}
                                                <Typography>{n.screen_name}</Typography>
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                {(function() {
                                                    switch(n.status){
                                                        case "waiting":
                                                            return <ReactSVG style={{color: "#ffa447"}} src={scheduleIcon} />
                                                        case "enabled":
                                                            return <a href={"https://twitter.com/"+n.screen_name} target="_blank" rel="noopener noreferrer">{n.display_name && <ReactSVG src={twitterIcon} />}</a>
                                                        case "disabled":
                                                            return <ReactSVG src={clearIcon} />
                                                        default:
                                                            return null
                                                    }
                                                })()}
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                {(function() {
                                                    switch(n.role){
                                                        case "User":
                                                            return "Membre";
                                                        case "Manager":
                                                            return "Gestionnaire";
                                                        default:
                                                            return n.role;
                                                    }
                                                })()}
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                {(function() {
                                                    switch(n.status){
                                                        case "waiting":
                                                            return "En attente de première connexion";
                                                        case "enabled":
                                                            return "Activé";
                                                        case "disabled":
                                                            return "Désactivé";
                                                        case "removed":
                                                            return "Supprimé";
                                                        default:
                                                            return n.status;
                                                    }
                                                })()}
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                <IconButton
                                                    className={classes.moreButton + " noOver"}
                                                    aria-label="actions"
                                                    aria-owns={isMenuOpen ? 'material-appbar' : null}
                                                    aria-haspopup="true"
                                                    disableRipple
                                                    onClick={(e)=>this.handleMenuOpen(e, n)}
                                                    color="inherit"
                                                >
                                                    <MoreHorizIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} style={{border: "none"}} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Page précédente',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Page suivante',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </Paper>
        );
    }
}

EnhancedTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);
