import Constants from '../utils/params';
import {catchErrors, refreshTokenAction, getUserInfosAction, checkToken} from './utils';

/*
  Login direct
 */
export function loginAction(email, password)
{
    return(dispatch)=>{
        return fetch(`${Constants.DOMAIN}/login`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: email, password: password})
        }).then(response => {
            if (!response.ok) {
                throw (response);
            }
            return response;
        })
        .then(res=>{
            return(res)
        })
        .catch(catchErrors)
    }
}

/*
  Récupère un nouveau token
 */
export function getNewTokenAction(refresh_token)
{
  return(dispatch)=>{
    return refreshTokenAction()
  }
}

/*
  Récupère les informations de l'utilisateur connecté
 */
export function getCurrentUserAction()
{
  return(dispatch)=>{

      return checkToken().then((t)=> {
          return getUserInfosAction()
              .then(res => {
                  dispatch({
                      type: "UPDATE_USER",
                      user: res
                  });
                  return (res)
              })
              .catch(catchErrors)
      });
  }
}

/*
  Met à jour les informations de l'utilisateur
*/
export function updateUserAction(user)
{
    return(dispatch)=>{
        dispatch({
            type:"UPDATE_USER",
            user:user
        });
    }
}

/*
  Met à jour les informations des utilisateurs
*/
export function updateUsersAction(users)
{
    return(dispatch)=>{
        dispatch({
            type:"UPDATE_USERS",
            users:users
        });
    }
}

/*
  Met à jour isAuthenticated
*/
export function updateIsAuthenticated(value)
{
  return(dispatch)=>{
    dispatch({
        type:"UPDATE_ISAUTHENTICATED",
        isAuthenticated:value
    });
  }
}
